import { Box, Divider, Flex } from '@chakra-ui/react';
import { iStartup } from '~/domain/interfaces/models';
import { GetOverviewStartup } from '~/domain/usecases/startup/remote';
import { IconItemNotice } from '~/presentation/base/icons';
import { Label } from '~/presentation/components/UI/Label';
import { Typography } from '~/presentation/components/UI/Typography';

type Steps = iStartup['records'][0]['incubations'][0]['incubationSteps'];
interface ownProps {
  startup?: iStartup['records'][0];
  overview?: GetOverviewStartup.Model;
}

const NoticeTab = ({ startup, overview }: ownProps) => {
  const renderBadge = (bgColor: string, color: string, text: string) => {
    return (
      <Typography
        variant='b3_Inter_Medium_12px'
        h='25px'
        color={color}
        bg={bgColor}
        borderRadius='3px'
        px='10px'
        py='5px'
      >
        {text}
      </Typography>
    );
  };

  const mapStatus = (status: string) => {
    switch (status) {
      case 'FINISHED':
        return renderBadge('green.4', 'green.1', 'Concluído');
      case 'REGULAR':
        return renderBadge('green.4', 'green.1', 'Incubado');
      case 'IRREGULAR':
        return renderBadge('yellow.6', 'yellow.1', 'Irregular');
      case 'SUSPENDED':
        return renderBadge('red.8', 'red.3', 'Desligado');
      case 'SUBMITTED':
        return renderBadge('white.5', 'black.2', 'Inscrito');
      default:
        return 'N/A';
    }
  };

  const getStep = (status: string, step?: Steps) => {
    // eslint-disable-next-line no-console
    console.log('>>>step', step, status);
    if (step)
      switch (status) {
        case 'FINISHED':
          return `Última etapa: ${step[step.length - 1].step.name}`;
        case 'REGULAR':
        case 'IRREGULAR':
          return `Etapa atual: ${
            step?.find((item) => item.status === 'IN PROGRESS')?.step.name
          }`;
        case 'SUSPENDED':
          return 'Etapa atual: N/A';
        default:
          return 'N/A';
      }
    else
      switch (status) {
        case 'SUBMITTED':
          return 'Inscrição: Em análise';
        default:
          return 'N/A';
      }
  };

  const Item = (id: number, title: string, status: string, step?: Steps) => {
    return (
      <Flex
        key={id}
        flexDirection={'row'}
        gap='60px'
        maxWidth={'min-content'}
        minW={'100%'}
        justifyContent={'space-between'}
        color='black.2'
        mb='12px'
        pl='6px'
      >
        <Flex gap='12px'>
          <IconItemNotice />
          <Box>
            <Typography
              variant='h7_Inter_Medium_14px'
              textTransform='capitalize'
            >
              {title}
            </Typography>
            <Typography variant='b1_Inter_Regular_14px' maxW='225px'>
              {getStep(status, step)}
            </Typography>
          </Box>
        </Flex>
        {mapStatus(status)}
      </Flex>
    );
  };

  return (
    <Flex h='503px' gap='32px'>
      <Flex
        flexDirection={'column'}
        flex='1'
        maxW='440px'
        maxH={'503px'}
        overflow={'auto'}
      >
        <Box mb='20px'>
          <Label>
            Editais (
            {(startup?.incubations.length ?? 0) +
              (startup?.applications.filter(
                (item) => item.status === 'SUBMITTED'
              ).length ?? 0)}
            )
          </Label>
        </Box>
        {startup?.incubations.slice().map((item) => {
          return Item(
            item.id,
            item.notice.title,
            item.status,
            item.incubationSteps
          );
        })}
        {startup?.applications
          .filter((item) => item.status === 'SUBMITTED')
          .map((item) => {
            return Item(item.id, item.notice.title, 'SUBMITTED');
          })}
      </Flex>
      <Divider
        orientation='vertical'
        borderColor='white.4'
        borderWidth='1px'
        height='100%'
      />
      {overview && (
        <Flex flexDirection='column'>
          {[
            {
              label: 'Recursos recebidos de editais',
              data: overview?.incubation.noticeIncomes,
            },
            {
              label: 'Despesas totais',
              data: overview?.incubation.expenses,
            },
            {
              label: 'Receita total',
              data: overview?.incubation.incomes,
            },
          ].map(({ label, data }) => (
            <Flex key={label} flexDirection='column'>
              <Label>{label}</Label>
              <Flex flexDirection='column' mt='20px' mb='12px' color='black.2'>
                <Typography variant='b1_Inter_Regular_14px'>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data || 0)}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default NoticeTab;
