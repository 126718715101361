/* eslint-disable no-console */
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import ButtonFilter from '~/presentation/components/ButtonFilter';
import { useFilterSubscriptions } from '~/presentation/context/filters/subscriptions';

interface ButtonFilterProps {
  showDownloadReportButton?: boolean;
}

const FilterSubscriptions = ({
  showDownloadReportButton,
}: ButtonFilterProps): JSX.Element => {
  const {
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    methods,
    numberOfResults,
    isOpen,
    onOpen,
    onClose,
  } = useFilterSubscriptions();

  const { records: startups } = useSelector((store: iStore) => store.startup);
  const { records: notices } = useSelector((store: iStore) => store.notice);

  return (
    <>
      <ButtonFilter
        filterActive={hasFilterActive}
        action={onOpen}
        showDownloadReportButton={showDownloadReportButton}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={(e) => methods.handleSubmit(e)}>
          <ModalContent
            maxW={{ base: '100%', breakpoint1024: '408px' }}
            minH={{ base: '574px', breakpoint1024: '522px' }}
            margin={{ base: '0px', breakpoint1024: 'auto' }}
            borderRadius={{
              base: '10px 10px 0px 0px',
              breakpoint1024: '10px 10px 10px 10px',
            }}
            containerProps={{
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            <ModalHeader fontFamily='Inter' fontSize='18px' fontWeight='700'>
              Filtrar consulta
            </ModalHeader>
            <ModalCloseButton
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            />
            <ModalBody fontFamily='Inter' fontWeight='600' fontSize='14px'>
              <Text mb='11px'>Edital associado</Text>

              <Select
                fontSize='14px'
                {...methods.getFieldProps('notice')}
                onChange={(e) => {
                  methods.setFieldValue('notice', Number(e.target.value));
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    notice:
                      e.target.value !== undefined
                        ? Number(e.target.value)
                        : undefined,
                  });
                }}
                css={{
                  ':active, :focus': {
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
              >
                <option
                  value={undefined}
                  style={{
                    color: '#222222',
                  }}
                >
                  Todos
                </option>
                {notices.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      style={{
                        color: '#222222',
                      }}
                    >
                      {item.code}
                    </option>
                  );
                })}
              </Select>

              <Text mt='20px' mb='11px'>
                Startup
              </Text>

              <Select
                placeholder='Todas'
                defaultValue={methods.values.startup}
                fontSize='14px'
                {...methods.getFieldProps('startup')}
                value={methods.values.startup}
                onChange={(e) => {
                  methods.setFieldValue('startup', Number(e.target.value));
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    startup: Number(e.target.value),
                  });
                }}
              >
                {startups.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      style={{
                        color: '#222222',
                      }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            </ModalBody>
            <Divider borderColor='#CECECE' />
            <ModalFooter
              gap='12px'
              justifyContent='space-between'
              flexDirection={{
                base: 'column-reverse',
                breakpoint1024: 'row',
              }}
            >
              <Button
                maxW='172px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='outline'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                borderColor='#9C9C9C'
                borderRadius='5px'
                color='#484848'
                px='24px'
                py='11.5px'
                _hover={{ bg: '#EDEDED' }}
                css={{
                  ':active, :focus': {
                    background: '#FDFDFD',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
                onClick={() => handleRestoreDefault()}
              >
                Restaurar padrões
              </Button>
              <Button
                maxW='137px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='solid'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                color='#FDFDFD'
                bg='#222222'
                px='24px'
                py='11.5px'
                type='submit'
                disabled={numberOfResults === 0}
                borderRadius='5px'
                _hover={{ bg: '#484848' }}
                css={{
                  ':active, :focus': {
                    background: '#222222',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
              >
                Aplicar filtros {`(${numberOfResults ?? 0})`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default FilterSubscriptions;
