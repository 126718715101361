import { Box, Button, HStack } from '@chakra-ui/react';
import React from 'react';
import IconFilterNew from '~/presentation/base/icons/iconFilterNew';
import { withAccess } from '~/presentation/hooks/acess';

interface ButtonFilterProps {
  action: () => void;
  showDownloadReportButton?: boolean;
  filterActive?: boolean;
}

const ButtonFilter: React.FC<ButtonFilterProps> = ({
  action,
  filterActive,
}: ButtonFilterProps): JSX.Element => {
  return (
    <Button
      // minW={'fit-content'}
      // height='36px'
      // background='#FDFDFD'
      // color='#222222'
      // border={'1px'}
      // borderColor={'#9C9C9C'}
      // _hover={{ background: '#EDEDED' }}
      // fontSize='sm'
      // fontWeight={'500'}
      variant='secondary'
      size='sm'
      onClick={action}
      position={'relative'}
    >
      <HStack>
        <IconFilterNew />
        <span>Filtros</span>
        {filterActive && (
          <Box
            position={'absolute'}
            top={0}
            right={0}
            w={2}
            h={2}
            rounded={'full'}
            transform={'translate(-50%, 50%)'}
            backgroundColor={'red'}
          />
        )}
      </HStack>
    </Button>
  );
};

export default withAccess(ButtonFilter);
