import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iUser } from '~/domain/interfaces/models/User';
import { makeReduxListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';
import { makeRemoteManageMentorNotice } from '~/main/factories/usecases/user/ManageMentorNoticeFactory';
import IconDot from '~/presentation/base/icons/iconDot';
import { Button } from '~/presentation/components/UI/Button';
import { Checkbox } from '~/presentation/components/UI/Checkbox';
import { Label } from '~/presentation/components/UI/Label';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import ConfirmChangesModal from './ConfirmChangesModal';
import DiscardChangesModal from './DiscardChangesModal';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  user?: iUser['records'][0];
}

const LinkUser = ({ isOpen, onClose, user }: OwnProps): JSX.Element => {
  const [selectedEditais, setSelectedEditais] = useState<number[]>([]);
  const [initialSelectedEditais, setInitialSelectedEditais] = useState<
    number[]
  >([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const toast = useToast();

  const notices = useSelector((store: iStore) => store.notice.records);

  const userNotices = notices.filter(
    (notice) =>
      Array.isArray(notice.mentors) &&
      notice.mentors.some((mentor) => mentor?.user?.id === user?.id)
  );

  useEffect(() => {
    if (isOpen && user) {
      const initialSelectedIds = userNotices.map((notice) => notice.id);
      setSelectedEditais(initialSelectedIds);
      setInitialSelectedEditais(initialSelectedIds);
      setSelectAll(
        initialSelectedIds.length > 0 &&
          initialSelectedIds.length === notices.length
      );
    }
  }, [isOpen, user, notices]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedEditais([]);
    } else {
      setSelectedEditais(notices.map((notice) => notice.id));
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const toggleEditalSelection = (id: number) => {
    setSelectedEditais((prevState) =>
      prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id]
    );
  };

  const hasChanges = () => {
    return (
      selectedEditais.length !== initialSelectedEditais.length ||
      selectedEditais.some((id) => !initialSelectedEditais.includes(id))
    );
  };

  const onCloseModal = useCallback(() => {
    if (hasChanges()) {
      setIsOpenDiscard(true);
    } else {
      onClose();
      setSelectAll(false);
      setSelectedEditais([]);
      // eslint-disable-next-line no-console
      console.log(notices);
    }
  }, [hasChanges, onClose, notices]);

  const formik = useFormik({
    initialValues: {
      noticeToAdd: [],
      noticeToDelete: [],
    },
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      user?.id &&
        makeRemoteManageMentorNotice()
          .manage({
            id: user.id,
            body: {
              noticeToAdd: selectedEditais
                .filter((id) => !userNotices.some((notice) => notice.id === id))
                .map((id) => ({ id, specialty: '' })),
              noticeToDelete: userNotices
                .filter((notice) => !selectedEditais.includes(notice.id))
                .map((notice) => notice.id),
            },
          })
          .then(() => {
            makeReduxListNotice().list({ query: { limit: 999 } });
            toast({
              title:
                'Os editais vinculados a este mentor foram alterados com sucesso!',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
              variant: 'left-accent',
              containerStyle: {
                maxWidth: 'max-content',
              },
            });
          })
          .catch(() => {
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            setIsLoading(false);
            onClose();
            setSelectAll(false);
            setSelectedEditais([]);
          });
    },
  });

  const handleSaveChangesClick = () => {
    if (hasChanges()) {
      setIsOpenConfirm(true);
    } else {
      onCloseModal();
    }
  };

  const handleCloseConfirmChanges = () => {
    setIsOpenConfirm(false);
  };

  const handleSaveChanges = () => {
    formik.handleSubmit();
    setIsOpenConfirm(false);
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW='800px' bg='#F8F8F8'>
          <ModalHeader fontSize='18px' py='3'>
            Vincular mentor a editais
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns='30% auto 60%' gap={4} alignItems='start'>
              <Flex flexDirection='column' gap='20px' alignItems='flex-end'>
                <Flex flexDirection='column' gap='10px' alignItems='flex-end'>
                  <Label>Nome do mentor</Label>
                  <Text fontSize='sm' color='#303950'>
                    {user?.fullname}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='10px' alignItems='flex-end'>
                  <Label>E-mail</Label>
                  <Text fontSize='sm' color='#303950'>
                    {user?.email}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='10px' alignItems='flex-end'>
                  <Label>Especialidade</Label>
                  <Text fontSize='sm' color='#303950'>
                    {/* user?.job */ 'Waiting update'}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='10px' alignItems='flex-end'>
                  <Label>Situação</Label>
                  <Text fontSize='sm' color='#303950'>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                      }}
                    >
                      {user?.situation === 'ACTIVE' ? (
                        <IconDot fillColor='#8BC314' />
                      ) : (
                        <IconDot fillColor='#CECECE' />
                      )}
                      {user?.situation === 'ACTIVE' ? 'Regular' : 'Irregular'}
                    </span>
                  </Text>
                </Flex>
              </Flex>

              <Box mx='10px'>
                <Divider orientation='vertical' />
              </Box>

              <Box>
                <Label>Este mentor estará vinculado a quais editais?</Label>
                <Checkbox isChecked={selectAll} onChange={toggleSelectAll}>
                  Todos
                </Checkbox>
                <Stack
                  maxH='300px'
                  overflowY='auto'
                  spacing={2}
                  mt={2}
                  marginLeft='20px'
                >
                  {notices.map((notice) => (
                    <Checkbox
                      key={notice.id}
                      isChecked={selectedEditais.includes(notice.id)}
                      onChange={() => toggleEditalSelection(notice.id)}
                    >
                      {notice.title}
                    </Checkbox>
                  ))}
                </Stack>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter pt='20px'>
            <Stack>
              <Divider
                orientation='horizontal'
                display='inline-flex'
                width='738px'
                position='relative'
                mb='12px'
              />
              <HStack justifyContent='space-between' w='100%' spacing='4'>
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'
                >
                  <Button size='md' variant='secondary' onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  <Button
                    size='md'
                    variant='primary'
                    isLoading={isLoading}
                    loadingText='Salvando alterações'
                    onClick={handleSaveChangesClick}
                  >
                    Salvar alterações
                  </Button>
                </Flex>
              </HStack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DiscardChangesModal
        isOpen={isOpenDiscard}
        onClose={onClose}
        onCloseDiscard={() => setIsOpenDiscard(false)}
      />
      <ConfirmChangesModal
        isOpen={isOpenConfirm}
        onClose={handleCloseConfirmChanges}
        onConfirm={handleSaveChanges}
      />
    </Box>
  );
};

export default LinkUser;
