import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadGateway,
  BadRequestError,
  Forbidden,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';
import { ListUser } from '~/domain/usecases/user/remote';
import '~/infra/global/variables';

export class RemoteListUser implements ListUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListUser.Model>;

  constructor(url: string, httpClient: HttpClient<ListUser.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (params: ListUser.Params): Promise<ListUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params.body,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
