import { ListAccountability as UsecaseReduxListAccountability } from '~/domain/usecases/accountability/redux';
import { ListAccountability as UsecaseRemoteListAccountability } from '~/domain/usecases/accountability/remote';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { RemoteListAccountability } from '~/data/repository/accountability';
import { ReduxListAccountability } from '~/data/store/reducer/accountability/usecases';

/**
 * send request via API.
 */
export const makeRemoteListAccountability =
  (): UsecaseRemoteListAccountability =>
    new RemoteListAccountability(
      makeApiUrl('/accountabilities/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListAccountability = (): UsecaseReduxListAccountability =>
  new ReduxListAccountability();
