import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import saveAs from 'file-saver';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import { makeApiUrl } from '~/main/factories/http';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

interface StartupsToGenerateReport {
  name: string;
  id: number;
  checked: boolean | undefined;
}

const ReportGenerator = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const accountabilities = useSelector(
    (store: iStore) => store.accountability.records
  );
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [startupsNameList, setStartupList] = React.useState<string[]>([]);
  const [startupsIdList, setStartupIdList] = React.useState<number[]>([]);
  const startupsAccountabilitiesArray: StartupsToGenerateReport[] = [];
  const [startupAccountabilities, setStartupAccountabilities] = React.useState<
    StartupsToGenerateReport[]
  >([]);
  const [checkedItems, setCheckedItems] = React.useState(
    Array(startupsNameList.length).fill(false)
  );

  const allChecked = checkedItems.every(Boolean || null);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    accountabilities &&
      accountabilities?.map((item, index) => {
        if (accountabilities[index - 1]?.startup?.name === item.startup?.name) {
          return;
        } else {
          startupsAccountabilitiesArray.push({
            name: item.startup?.name,
            id: item.startup?.id,
            checked: false,
          });
        }
      });
    setStartupList(
      Array.from(
        new Set(startupsAccountabilitiesArray.map((startup) => startup.name))
      )
    );
    setStartupAccountabilities(startupsAccountabilitiesArray);
  }, [accountabilities, checkedItems]);

  useEffect(() => {
    const updatedAccountabilities: StartupsToGenerateReport[] =
      startupsNameList.map((name, index) => ({
        name,
        id: startupAccountabilities[index]?.id,
        checked: checkedItems[index],
      }));
    setStartupAccountabilities(updatedAccountabilities);
  }, [checkedItems, startupsNameList, startupsIdList]);

  const handleOnSubmit = React.useCallback(async (startups: number[]) => {
    setIsLoading(true);
    try {
      axios
        .post(
          makeApiUrl('/accountabilities/report'),
          { startups: startups },
          {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          saveAs(response.data, 'relatorio_de_informes.pdf');
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error generating report:', error);
          showConfirmModal('APPLICATION_ERROR');
        })
        .finally(() => {
          setIsLoading(false);
          handleOnClose();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating report:', error);
      showConfirmModal('APPLICATION_ERROR');
    } finally {
      setIsLoading(false);
      handleOnClose();
    }
  }, []);

  const handleIsStartupsSelected = React.useCallback(() => {
    const selectedStartups = startupAccountabilities.filter(
      (startup) => startup.checked
    );
    return selectedStartups.length > 0 ? true : false;
  }, [startupAccountabilities]);

  const handleOnClose = React.useCallback(() => {
    onClose();
    setCheckedItems(Array(startupsNameList.length).fill(false));
  }, [allChecked]);

  useEffect(() => {
    //initializing "Todas" as false
    setCheckedItems([...checkedItems, false]);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleOnClose} size='sm' isCentered>
        <ModalOverlay />
        <ModalContent bg='#F9F8FA'>
          <ModalHeader
            fontSize={{ base: 'lg' }}
            fontWeight={{ base: '700' }}
            paddingTop={{ base: '27px', md: '20px' }}
            paddingX={{ base: '20px', md: '20px' }}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            Relatório de finanças?
            <ModalCloseButton
              style={{ position: 'static' }}
              onClick={() => {
                handleOnClose();
              }}
            />
          </ModalHeader>

          <ModalBody
            paddingX={'24px'}
            paddingBottom={'20px'}
            paddingTop={'4px'}
            overflowY={'auto'}
            maxH='309px'
          >
            <VStack align={'left'} gap={'12px'}>
              {accountabilities && (
                <Checkbox
                  isChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) =>
                    setCheckedItems(
                      Array(startupsNameList.length).fill(e.target.checked)
                    )
                  }
                >
                  Todas
                </Checkbox>
              )}
              <Stack pl={6} mt={1} spacing={1}>
                {startupsNameList.map((item, index) => (
                  <Checkbox
                    key={index}
                    isChecked={checkedItems[index]}
                    onChange={(e) => {
                      const newCheckedItems = [...checkedItems];
                      newCheckedItems[index] = e.target.checked;
                      setCheckedItems(newCheckedItems);
                    }}
                  >
                    {item}
                  </Checkbox>
                ))}
              </Stack>
            </VStack>
          </ModalBody>
          <ModalFooter
            padding={{ base: '20px' }}
            display={'flex'}
            flexDirection={'column'}
            borderTop={'1px solid #CECECE'}
          >
            <Button
              w='100%'
              color='#F9F8FA'
              backgroundColor={{ base: '#222222' }}
              _hover={{ background: '#484848' }}
              _active={{ background: '#484848' }}
              fontFamily={'Inter'}
              fontSize={{ base: 'sm' }}
              fontWeight={{ base: '500' }}
              borderRadius={'5px'}
              isLoading={isLoading}
              isDisabled={!handleIsStartupsSelected()}
              onClick={() => {
                const selectedStartupsIds: number[] = startupAccountabilities
                  .filter((item) => item.checked === true)
                  .map((item) => item.id);
                handleOnSubmit(selectedStartupsIds);
              }}
            >
              Baixar relatório
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportGenerator;
