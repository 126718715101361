import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  HStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FormRegisterEvent from '../../../Form/RegisterEvent/FormRegisterEvent';
import { mainSchema } from '~/validators/RegisterEvent/RegisterEventValidator';
import { makeRemoteUpdateEvent } from '~/main/factories/usecases/event/UpdateEventFactory';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import getTypeEvent from '~/utils/getTypeEvent';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import TypeEvent from '../RegisterEvent/TypeEvent';
import Form from '~/presentation/components/UI/FormFormik';
import { Button } from '~/presentation/components/UI/Button';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';

interface EditEventProps {
  isOpen: boolean;
  onClose: () => void;
  event: any;
}

const EditEvent = ({ isOpen, onClose, event }: EditEventProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableTabs, setDisableTabs] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useSelector((store: iStore) => store.auth);
  const currentResponsable = event?.responsable
    ? event.responsable.id
    : undefined;

  const handleClose = () => {
    formik.resetForm({ values: mainSchema.initial });
    setDisableTabs(false);
    setSelectedTab(0);
    setSelectedOptions([]);
    onClose();
  };

  const formik = useFormik({
    initialValues: mainSchema.initial,
    validationSchema: mainSchema.validators,
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      const date = new Date(values.date || new Date());
      let duration: number | undefined;

      if (selectedTab === 2) {
        const time = values.time ? new Date(values.time) : new Date();
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
      } else {
        const startTime = new Date(values.startTime || new Date());
        const endTime = new Date(values.endTime || new Date());
        startTime.setSeconds(0, 0);
        endTime.setSeconds(0, 0);
        duration = Math.round((endTime.getTime() - startTime.getTime()) / 1000);
        if (duration <= 0) duration = 1;
      }

      const baseBody: any = {
        date: date.toISOString(),
        name: values.name,
        type: getTypeEvent(selectedTab),
        duration: selectedTab !== 2 ? duration : undefined,
        link: values.link || undefined,
        description: values.description || undefined,
      };

      if (selectedTab === 0) {
        baseBody.users = values.guests?.map(Number) || [];
        baseBody.responsable = currentResponsable;
      } else if (selectedTab === 1) {
        baseBody.startups = values.guests?.map(Number) || [];
        baseBody.responsable = Number(values.responsable?.value);
      } else if (selectedTab === 2) {
        baseBody.startups = values.guests?.map(Number) || [];
        baseBody.notice = values.notice
          ? Number(values.notice.value)
          : undefined;
        baseBody.noticeStep = values.noticeStep
          ? Number(values.noticeStep.value)
          : undefined;
        baseBody.responsable = currentResponsable;
      }

      const requestBody = { body: baseBody };

      setIsLoading(true);
      makeRemoteUpdateEvent()
        .update({ id: event.id, body: requestBody.body })
        .then(() => {
          makeReduxListEvent().list({});
          showConfirmModal('EDIT_EVENT');
        })
        .catch(() => {
          showConfirmModal('APPLICATION_ERROR');
        })
        .finally(() => {
          resetForm({ values: mainSchema.initial });
          setSelectedOptions([]);
          setIsLoading(false);
          onClose();
        });
    },
  });

  useEffect(() => {
    if (!event || !isOpen) return;

    const tab =
      event.type === 'MEETING' ? 0 : event.type === 'MENTORING' ? 1 : 2;
    setSelectedTab(tab);
    setDisableTabs(true);

    const updatedValues: {
      name: string;
      date: Date;
      link: string;
      description: string;
      guests: any[];
      time?: Date;
      startTime?: Date;
      endTime?: Date;
      responsable?: { value: any; label: string };
      notice?: { value: any; label: string };
      noticeStep?: { value: any; label: string };
    } = {
      name: event.name || '',
      date: new Date(event.date),
      link: event.link || '',
      description: event.description || '',
      guests:
        event.type === 'MENTORING' && event.startups
          ? event.startups.map((startup: any) => startup.id)
          : event.users
          ? event.users.map((user: any) => user.id)
          : [],
    };

    if (tab === 2) {
      updatedValues.time = new Date(event.date);
      if (event.notice) {
        updatedValues.notice = {
          value: event.notice.id,
          label: event.notice.title,
        };
      }
      if (event.step) {
        updatedValues.noticeStep = {
          value: event.step.id,
          label: event.step.name,
        };
      }
    } else {
      updatedValues.startTime = new Date(event.startAt);
      updatedValues.endTime = new Date(event.endAt);

      if (event.responsable) {
        updatedValues.responsable = {
          value: event.responsable.id,
          label: event.responsable.fullname,
        };
      }
    }

    formik.setValues({
      ...formik.values,
      ...updatedValues,
    });
  }, [event, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW='800px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Editar Evento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form value={formik}>
            <Flex justify='space-between' gap='32px'>
              <FormRegisterEvent selectedTab={selectedTab} />
              <TypeEvent
                isEdit
                formik={formik}
                setSelectedTab={setSelectedTab}
                setSelectedOptions={setSelectedOptions}
                selectedTab={selectedTab}
                selectedOptions={selectedOptions}
                disableTabs={disableTabs}
                actions={['ADMIN', 'SUPERVISOR', 'MENTOR', 'MEMBER', 'LEADER']}
                resourcers={[
                  'ADMIN',
                  'SUPERVISOR',
                  'MENTOR',
                  'MEMBER',
                  'LEADER',
                ]}
              />
            </Flex>
          </Form>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button variant='secondary' onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              id='submit'
              w={{ base: '100%', md: 'auto' }}
              isLoading={isLoading}
              loadingText='Salvando alterações'
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Salvar alterações
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditEvent;
