/* eslint-disable no-console */
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import ButtonFilter from '~/presentation/components/ButtonFilter';
import { SliderField } from '~/presentation/components/UI/Slider/field';
import { useFilterNotices } from '~/presentation/context/filters/notices';
import { currencyFormatter } from '~/utils/currency';
import { decimalFormatter } from '~/utils/decimal';

interface ButtonFilterProps {
  showDownloadReportButton?: boolean;
}

const FilterNotices = ({
  showDownloadReportButton,
}: ButtonFilterProps): JSX.Element => {
  const {
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    methods,
    numberOfResults,
    isOpen,
    onOpen,
    onClose,
  } = useFilterNotices();

  const { value, vacancies } = useSelector((store: iStore) => store.notice);

  return (
    <>
      <ButtonFilter
        filterActive={hasFilterActive}
        action={onOpen}
        showDownloadReportButton={showDownloadReportButton}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={(e) => methods.handleSubmit(e)}>
          <ModalContent
            maxW={{ base: '100%', breakpoint1024: '408px' }}
            minH={{ base: '574px', breakpoint1024: '522px' }}
            margin={{ base: '0px', breakpoint1024: 'auto' }}
            borderRadius={{
              base: '10px 10px 0px 0px',
              breakpoint1024: '10px 10px 10px 10px',
            }}
            containerProps={{
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            <ModalHeader fontFamily='Inter' fontSize='18px' fontWeight='700'>
              Filtrar consulta
            </ModalHeader>
            <ModalCloseButton
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            />
            <ModalBody fontFamily='Inter' fontWeight='600' fontSize='14px'>
              <Text mb='11px'>Valor do edital</Text>
              <SliderField
                min={value.min}
                max={value.max}
                defaultValue={[value.min, value.max]}
                step={500}
                minStepsBetweenThumbs={1000}
                extraContent={(values) => {
                  const range = values ?? [value.min, value.max];
                  return (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'full'}
                    >
                      {range.map((value, index) => (
                        <Text key={index}>{currencyFormatter(value)}</Text>
                      ))}
                    </Box>
                  );
                }}
                {...methods.getFieldProps('financial')}
                onChangeEnd={(value) => {
                  methods.setFieldValue('financial', value);
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    financial: value,
                  });
                }}
                onChange={(value) => {
                  methods.setFieldValue('financial', value);
                }}
              />

              <Text mb={3} mt={5}>
                Quantidade de vagas
              </Text>
              <SliderField
                min={vacancies.min}
                max={vacancies.max}
                defaultValue={[vacancies.min, vacancies.max]}
                step={1}
                minStepsBetweenThumbs={2}
                extraContent={(values) => {
                  const range = values ?? [vacancies.min, vacancies.max];
                  return (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'full'}
                    >
                      {range.map((value, index) => (
                        <Text key={index}>{decimalFormatter(value)}</Text>
                      ))}
                    </Box>
                  );
                }}
                {...methods.getFieldProps('vacancies')}
                onChangeEnd={(value) => {
                  methods.setFieldValue('vacancies', value);
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    vacancies: value,
                  });
                }}
                onChange={(value) => {
                  methods.setFieldValue('vacancies', value);
                }}
                name='vacancies'
              />
            </ModalBody>
            <Divider borderColor='#CECECE' />
            <ModalFooter
              gap='12px'
              justifyContent='space-between'
              flexDirection={{
                base: 'column-reverse',
                breakpoint1024: 'row',
              }}
            >
              <Button
                maxW='172px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='outline'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                borderColor='#9C9C9C'
                borderRadius='5px'
                color='#484848'
                px='24px'
                py='11.5px'
                _hover={{ bg: '#EDEDED' }}
                css={{
                  ':active, :focus': {
                    background: '#FDFDFD',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
                onClick={() => handleRestoreDefault()}
              >
                Restaurar padrões
              </Button>
              <Button
                maxW='137px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='solid'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                color='#FDFDFD'
                bg='#222222'
                px='24px'
                py='11.5px'
                type='submit'
                disabled={numberOfResults === 0}
                borderRadius='5px'
                _hover={{ bg: '#484848' }}
                css={{
                  ':active, :focus': {
                    background: '#222222',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
              >
                Aplicar filtros {`(${numberOfResults ?? 0})`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default FilterNotices;
