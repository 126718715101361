import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import { ComponentProps, forwardRef } from 'react';

type SliderProps = ComponentProps<typeof RangeSlider>;

const Slider = forwardRef<HTMLInputElement, SliderProps>(
  (props: SliderProps, ref) => {
    return (
      <Box width='full'>
        <RangeSlider aria-label={['min', 'max']} step={5} {...props} ref={ref}>
          <RangeSliderTrack>
            <RangeSliderFilledTrack bg={'black'} />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} borderColor={'black.4'} />
          <RangeSliderThumb index={1} borderColor={'black.4'} />
        </RangeSlider>
      </Box>
    );
  }
);
Slider.displayName = 'Slider';

export { Slider };
