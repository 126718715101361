import * as yup from 'yup';
import { validateCNPJ } from 'validations-br';

export const schemaRegisterStartup = {
  initial: {
    name: '',
    cnpj: '',
    website: null,
    address: '',
    cep: '',
    nameManager: '',
    roleManager: '',
    emailManager: '',
    activity: null,
    city: null,
    instagram: null,
    gender: null,
    phoneManager: null,
    cargaHoraria: null,
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    cnpj: yup
      .string()
      .matches(
        /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
        'CNPJ inválido'
      )
      .test('is-cnpj', 'CNPJ inválido', (cnpj) =>
        cnpj ? validateCNPJ(cnpj) : true
      ),
    website: yup.string().url('Site inválido').nullable(),
    address: yup.string().required('Campo obrigatório'),
    cep: yup.string().matches(/^(58\d{3})-\d{3}$/, 'CEP inválido'),
    nameManager: yup.string().required('Campo obrigatório'),
    roleManager: yup.string(),
    emailManager: yup
      .string()
      .required('Campo obrigatório')
      .email('Email inválido'),
    activity: yup
      .object()
      .shape({
        value: yup.string().required('Campo obrigatório'),
        label: yup.string().optional(),
      })
      .nullable()
      .required('Campo obrigatório'),
    city: yup
      .object()
      .shape({
        value: yup.string().required('Campo obrigatório'),
        label: yup.string().optional(),
      })
      .nullable()
      .required('Campo obrigatório'),
    instagram: yup.string().nullable(),
    gender: yup
      .object()
      .shape({
        value: yup
          .string()
          .required('Campo obrigatório')
          .oneOf(['MALE', 'FEMALE', 'OTHER'], 'Selecione uma opção válida'),
        label: yup.string().optional(),
      })
      .nullable()
      .required('Campo obrigatório'),
    phoneManager: yup.string().nullable(),
    cargaHoraria: yup
      .object()
      .shape({
        value: yup
          .string()
          .required('Campo obrigatório')
          .oneOf(['20h/semana', '40h/semana'], 'Selecione uma opção válida'),
        label: yup.string().optional(),
      })
      .nullable(),
  }),
};
