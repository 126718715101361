import {
  Box,
  Button,
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as datefns from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { iNotice } from '~/domain/interfaces/models';
import { iStore } from '~/domain/interfaces/models/Store';
import {
  IconDelete,
  IconEdit,
  IconOpenFolder,
  IconStage,
  IconView,
} from '~/presentation/base/icons';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import ConflictError from '~/presentation/components/Modals/Confirmation/ConflictError';
import DeleteNoticeSuccess from '~/presentation/components/Modals/Confirmation/DeleteNoticeSuccess';
import EditNoticeSuccess from '~/presentation/components/Modals/Confirmation/EditNoticeSuccess';
import RegisterNoticeSuccess from '~/presentation/components/Modals/Confirmation/RegisterNoticeSuccess';
import SubmitApplicationSucess from '~/presentation/components/Modals/Confirmation/SubmitApplicationSucess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeleteNotice from '~/presentation/components/Modals/notice/DeleteNotice';
import DetailsNotice from '~/presentation/components/Modals/notice/DetailsNotice';
import EditNotice from '~/presentation/components/Modals/notice/EditNotice';
import RegisterNotice from '~/presentation/components/Modals/notice/RegisterNotice';
import StageNotice from '~/presentation/components/Modals/notice/StageNotice';
import NoticeEnrollment from '~/presentation/components/NoticeEnrollment';
import Tab from '~/presentation/components/Tab';
import { withAccess } from '~/presentation/hooks/acess';
import { currencyFormatter } from '~/utils/currency';
import { decimalFormatter } from '~/utils/decimal';
import getDate from '~/utils/getDate';

interface iProps {
  item?: iNotice['records'][0];
  disabled?: boolean;
}

const Announcement: React.FC = (): JSX.Element => {
  const [open, setOpen] = React.useState('');
  const openRegisterNotice = () => {
    setOpen('REGISTER');
  };
  const [selectedNotice, setSelectedNotice] = React.useState<
    iNotice['records'][0] | undefined
  >(undefined);
  const [active, setActive] = React.useState('INSCRIÇÕES ABERTAS');
  const { records } = useSelector((store: iStore) => store.notice);

  const { selectedStartup: workspaceSelectedStartup } = useSelector(
    (store: iStore) => store.auth
  );

  const applications = useSelector((store: iStore) =>
    store.application.records?.filter(
      (el) => el.startup?.id === workspaceSelectedStartup?.id
    )
  );
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');

  const Apply = withAccess(({ item, disabled }: iProps): JSX.Element => {
    return (
      <Button
        background='#F9F8FA'
        border='1px solid #CECECE'
        fontWeight='regular'
        _hover={{ background: '#E9ECEE' }}
        onClick={() => {
          setOpen('ENROLLMENT'), setSelectedNotice(item);
        }}
        disabled={disabled}
      >
        Inscrever-se
      </Button>
    );
  });

  return (
    <Box
      flex='1'
      my='6'
      mx={{ base: 'auto', md: '8' }}
      h='calc(100% - 136px)'
      position='relative'
      display='flex'
      flexDirection='column'
    >
      <Tab
        active={active}
        setActive={setActive}
        tabs={[
          { title: 'INSCRIÇÕES ABERTAS' },
          { title: 'INSCRIÇÕES ENCERRADAS' },
        ]}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        buttonText='Novo Edital'
        searchPlaceholder='Pesquisar editais...'
        actions={['ADMIN', 'SUPERVISOR']}
        resourcers={['ADMIN', 'SUPERVISOR']}
        action={() => setOpen('REGISTER')}
      />
      {active === 'INSCRIÇÕES ABERTAS' && (
        <TableContainer
          flex='1'
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          borderBottom='1px solid #cfcfcf'
          borderBottomRadius={8}
          /* maxHeight='calc(100% - 190px)' */
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead position='sticky' top={0} zIndex={5} height='50px'>
              <Tr bg='white.3'>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Identificação
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Objeto do edital
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Inscrições
                </Th>
                <Th
                  fontSize='sm'
                  textTransform={'capitalize'}
                  textAlign='center'
                >
                  Vagas
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Valor P/ Projetos
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Ações
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {[...records]
                .sort((a, b) => -a.id + b.id)
                ?.filter((el) => {
                  return datefns.isFuture(new Date(el.activitiesEndAt));
                })
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return (
                      `${item.code + ' ' + item.title}`
                        .toLowerCase()
                        .search(filterByNameValue.toLowerCase()) !== -1
                    );
                  }
                })
                ?.map((item, index) => {
                  const isRegistered = applications.some(
                    (application) => application.notice?.id === item.id
                  );
                  return (
                    <Tr
                      fontSize={14}
                      fontWeight={400}
                      color='#747C86'
                      key={index}
                    >
                      <Td>
                        <Stack spacing='0'>
                          <Text>{`N° ${item.code}`}</Text>
                          <Text>{item.title}</Text>
                        </Stack>
                      </Td>
                      <Td maxW='100px'>
                        <Text textOverflow='ellipsis' overflow='hidden'>
                          {item.description}
                        </Text>
                      </Td>
                      <Td>{`${getDate(item.activitiesStartAt)} até ${getDate(
                        item.activitiesEndAt
                      )}`}</Td>
                      <Td textAlign='right'>
                        {decimalFormatter(item.vacancies)}
                      </Td>
                      <Td textAlign='right'>{currencyFormatter(item.value)}</Td>
                      <Td>
                        <Flex gap={15}>
                          <ActionButton
                            onClick={() => {
                              setOpen('DETAILS'), setSelectedNotice(item);
                            }}
                          >
                            <IconView />
                          </ActionButton>
                          <ActionButton
                            onClick={() => {
                              setOpen('STAGE'), setSelectedNotice(item);
                            }}
                            disabled={item?.steps?.length === 0}
                          >
                            <IconStage />
                          </ActionButton>
                          <ActionButton>
                            <Link
                              style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              to={{
                                pathname: '/documentos',
                                state: {
                                  path: [
                                    { name: 'INCUBADORA', id: 0 },
                                    {
                                      name: item?.code,
                                      id: item?.subscriptionFolder.files,
                                    },
                                  ],
                                },
                              }}
                            >
                              <IconOpenFolder />
                            </Link>
                          </ActionButton>
                          <ActionButton
                            actions={['ADMIN', 'SUPERVISOR']}
                            resourcers={['ADMIN', 'SUPERVISOR']}
                            onClick={() => {
                              setOpen('EDIT'), setSelectedNotice(item);
                            }}
                          >
                            <IconEdit />
                          </ActionButton>
                          <ActionButton
                            actions={['ADMIN', 'SUPERVISOR']}
                            resourcers={['ADMIN', 'SUPERVISOR']}
                            onClick={() => {
                              setOpen('DELETE'), setSelectedNotice(item);
                            }}
                          >
                            <IconDelete />
                          </ActionButton>
                          <Apply
                            actions={['LEADER']}
                            resourcers={['LEADER']}
                            item={item}
                            disabled={
                              isRegistered ||
                              !datefns.isPast(new Date(item.activitiesStartAt))
                            }
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {active === 'INSCRIÇÕES ENCERRADAS' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          maxHeight='calc(100% - 190px)'
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead position='sticky' top={0} zIndex={5} height='50px'>
              <Tr bg='white.3'>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Identificação
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Objeto do edital
                </Th>
                {/* <Th fontSize='sm'>Inscritos</Th> */}
                <Th
                  fontSize='sm'
                  textTransform={'capitalize'}
                  textAlign='center'
                >
                  Vagas
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Valor P/ Projetos
                </Th>
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Ações
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {[...records]
                .sort((a, b) => b.id - a.id)
                ?.filter((el) => {
                  return datefns.isPast(new Date(el.subscriptionEndAt));
                })
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return `${item.code + ' ' + item.title}`
                      .toLowerCase()
                      .startsWith(filterByNameValue.toLowerCase());
                  }
                })
                ?.map((item, index) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td>
                      <Stack spacing='0'>
                        <Text>{`N° ${item.code}`}</Text>
                        <Text>{item.title}</Text>
                      </Stack>
                    </Td>
                    <Td maxW='100px'>
                      <Text textOverflow='ellipsis' overflow='hidden'>
                        {item.description}
                      </Text>
                    </Td>
                    {/*TODO <Td textAlign='center'>{Math.floor(Math.random() * 10)}</Td> */}
                    <Td textAlign='right'>
                      {decimalFormatter(item.vacancies)}
                    </Td>
                    <Td textAlign='right'>{currencyFormatter(item.value)}</Td>
                    <Td display='flex' gap={15}>
                      <ActionButton
                        onClick={() => {
                          setOpen('DETAILS'), setSelectedNotice(item);
                        }}
                      >
                        <IconView />
                      </ActionButton>
                      <ActionButton
                        onClick={() => {
                          setOpen('STAGE'), setSelectedNotice(item);
                        }}
                        disabled={item?.steps?.length === 0}
                      >
                        <IconStage />
                      </ActionButton>
                      <ActionButton>
                        <Link
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          to={{
                            pathname: '/documentos',
                            state: {
                              path: [
                                { name: 'INCUBADORA', id: 0 },
                                {
                                  name: item?.title,
                                  id: item?.subscriptionFolder?.id,
                                },
                              ],
                            },
                          }}
                        >
                          <IconOpenFolder />
                        </Link>
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => {
                          setOpen('EDIT'), setSelectedNotice(item);
                        }}
                      >
                        <IconEdit />
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => {
                          setOpen('DELETE'), setSelectedNotice(item);
                        }}
                      >
                        <IconDelete />
                      </ActionButton>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <NoticeEnrollment
        isOpen={open === 'ENROLLMENT'}
        onClose={() => setOpen('')}
        notice={selectedNotice}
      />
      <RegisterNotice
        isOpen={open === 'REGISTER'}
        onClose={() => setOpen('')}
      />
      <StageNotice
        isOpen={open === 'STAGE'}
        onClose={() => setOpen('')}
        notice={selectedNotice}
      />
      <DetailsNotice
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        notice={selectedNotice}
      />
      <DeleteNotice
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        id={selectedNotice?.id}
        notice={selectedNotice}
      />
      <EditNotice
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        notice={selectedNotice}
      />
      <SubmitApplicationSucess />
      <RegisterNoticeSuccess
        onOpenRegister={openRegisterNotice}
      ></RegisterNoticeSuccess>
      <EditNoticeSuccess></EditNoticeSuccess>
      <SubmitionApplicationError></SubmitionApplicationError>
      <ConflictError customText='Já existe um edital cadastrado com este número'></ConflictError>
      <DeleteNoticeSuccess notice={selectedNotice}></DeleteNoticeSuccess>
    </Box>
  );
};

export default Announcement;
