import { iAccountability } from '~/domain/interfaces/models/Accountability';
import { AccountabilityTypes } from '~/domain/interfaces/redux/accountability/types';
import { AccountabilityActions } from './actions';

export const initialState: iAccountability = {
  loading: false,
  max: 0,
  min: 0,
  records: [],
};

const reducer = (
  state = initialState,
  action: AccountabilityActions
): iAccountability => {
  switch (action.type) {
    case AccountabilityTypes.LIST:
      return { ...state, loading: true };
    case AccountabilityTypes.LIST_FAILED:
      return { ...state, loading: false };
    case AccountabilityTypes.LIST_SUCCESS: {
      const { min, max } = state;
      return { ...action.payload, min, max, loading: false  };
    }
    case AccountabilityTypes.SET_MAX_MIN_VALUES: {

      const { records } = state;
      const maxValue = records.reduce((acc, record) => {
        return record.value > acc ? record.value : acc;
      }
      , 0);
      const minValue = records.reduce((acc, record) => {
        return record.value < acc ? record.value : acc;
      }
      , maxValue);

      return {
        ...state,
        loading: false,
        max: maxValue,
        min: minValue,
      };
    }
    default:
      return state;
  }
};

export default reducer;
