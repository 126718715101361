/* eslint-disable no-console */
import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';

type AccessContextData = {
  setPermissions(permissions: string[]): void;
  hasAccess(resourcers: string[], actions: string[]): boolean;
};

const AccessContext = createContext<AccessContextData>({} as AccessContextData);

interface iACL {
  [key: string]: boolean;
}

interface ownProps {
  children: React.ReactNode;
}

const AccessProvider: React.FC<ownProps> = ({ children }) => {
  // consulting redux.
  const role = useSelector((store: iStore) => store.auth.user?.role_);
  const RolesACL = [role?.name ? role.name : ''];
  const accessActions: string[] = [];
  const accessResources: string[] = [];
  const ACL: string[] = RolesACL;

  // eslint-disable-next-line no-console
  /* console.log('##access ACL: ', ACL); */

  const setPermissions = useCallback((permissions: string[]) => {
    console.log(permissions);
  }, []);

  const hasAccess = useCallback(
    (resourcers: string[], actions: string[]): boolean => {
      /* console.log('###access verificando'); */
      for (let i = 0; i < resourcers.length; i += 1) {
        if (ACL && ACL.find((el) => el === resourcers[i])) {
          /* console.log('###acess true'); */
          return true;
        }
      }
      console.log('###acess false');
      return false;
    },
    [accessResources, accessActions]
  );

  return (
    <AccessContext.Provider value={{ setPermissions, hasAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

const useAccess = () => {
  const context = useContext(AccessContext);
  return context;
};

type withAccessProps = {
  resourcers?: string[];
  actions?: string[];
};

function withAccess<P extends object>(
  Component: ComponentType<P>
): React.FC<P & withAccessProps> {
  const WithAccessComp: React.FC<P & withAccessProps> = ({
    resourcers,
    actions,
    ...rest
  }) => {
    const { hasAccess } = useAccess();

    const access = useMemo(() => {
      if (!resourcers || !actions) return true;

      return hasAccess(resourcers, actions);
    }, [resourcers, actions, hasAccess]);

    // eslint-disable-next-line no-constant-condition
    return access ? <Component {...(rest as P)} /> : <></>;
  };

  WithAccessComp.displayName = `withPermissions(${
    Component.displayName || Component.name
  })`;

  return WithAccessComp;
}

export { AccessProvider as default, useAccess, withAccess };
