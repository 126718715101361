import { ErrorMessage } from 'formik';
import React, { cloneElement } from 'react';

interface IProps {
  name: string;
  children: React.ReactElement;
  showError?: boolean;
}

const FormItem = ({ children, name, showError = true }: IProps) => {
  return (
    <div>
      {cloneElement(children, { name })}
      {showError && <ErrorMessage name={name} />}
    </div>
  );
};

export default FormItem;
