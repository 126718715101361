/* eslint-disable no-console */
import { Box, Stack, Text } from '@chakra-ui/react';
import * as datefns from 'date-fns';
import { useCallback, useContext } from 'react';
import { iEvent } from '~/domain/interfaces/models/Event';
import { IconDeleteDefault } from '~/presentation/base/icons';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
// import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

interface ownProps {
  event?: iEvent['records'][0];
}

const DeleteEventSucess = ({ event }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  const getEventConfirmationDeleteModalTitleByType = (eventType?: string) => {
    switch (eventType) {
      case 'MEETING':
        return 'Reunião excluída';
      case 'MENTORING':
        return 'Mentoria excluída';
      case 'DELIVERY':
        return 'Entrega excluída';
      default:
        return '';
    }
  };

  const getEventConfirmationDelteModalBodyByType =
    useCallback((): JSX.Element => {
      // eslint-disable-next-line no-console
      console.log('event:', event); // Logging f format(parsedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });or debugging
      switch (event?.type) {
        case 'MEETING':
          return (
            <Text textAlign={'center'}>
              A reunião <b>{event?.name}</b>, que estava agendada para o dia
              {datefns.format(
                new Date(event?.date),
                " dd 'de' MMMM 'de' yyyy ",
                { locale: ptBR }
              )}{' '}
              foi excluída com sucesso.
            </Text>
          );
        case 'MENTORING':
          return (
            <Text textAlign={'center'}>
              A mentoria com <b>{event?.responsable?.fullname}</b>, que estava
              agendada para o dia{' '}
              {datefns.format(
                new Date(event?.date),
                " dd 'de' MMMM 'de' yyyy ",
                { locale: ptBR }
              )}
              foi excluída com sucesso.
            </Text>
          );
        case 'DELIVERY':
          return (
            <Text textAlign={'center'}>
              A entrega <b>{event?.name}</b>, associada à etapa{' '}
              {event?.step?.name} do Edital nº {event?.notice?.code}-
              {event?.notice?.title}, foi excluída com sucesso.
            </Text>
          );
        default:
          return <Text>Evento excluído com sucesso!</Text>;
      }
    }, [event]);

  return (
    <Confirmation
      isOpen={active === 'DELETE_EVENT'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconDeleteDefault />}
      showTopButton={false}
      title={getEventConfirmationDeleteModalTitleByType(event?.type)}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            {event ? getEventConfirmationDelteModalBodyByType() : null}
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default DeleteEventSucess;
