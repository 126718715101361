import { useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import {
  makeReduxListApplication,
  makeRemoteListApplication,
} from '~/main/factories/usecases/application/ListApplicationFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { FilterContextProps, FilterProviderProps } from './types';

export type FilterSubscriptionsFields = {
  startup?: number;
  notice?: number;
};

type FilterSubscriptionsContextProps =
  FilterContextProps<FilterSubscriptionsFields>;

const FilterSubscriptionsContext = createContext<
  FilterSubscriptionsContextProps | undefined
>(undefined);

const initialState: FilterSubscriptionsFields = {
  notice: undefined,
  startup: undefined,
};

export const FilterSubscriptionsProvider: React.FC<FilterProviderProps> = ({
  children,
}) => {
  const [filter, setFilter] = useState<FilterSubscriptionsFields | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { metadata } = useSelector((store: iStore) => store.user);

  const [numberOfResults, setNumberOfResults] = useState<number | null>(
    () => metadata?.items ?? null
  );

  const methods = useFormik<FilterSubscriptionsFields>({
    initialValues: filter ?? initialState,
    onSubmit: (values) => handleFilter(values),
    validateOnChange: true,
  });

  const hasFilterActive = useMemo((): boolean => {
    return filter !== null;
  }, [filter]);

  const handleOnClose = useCallback(() => {
    onClose();
    methods.resetForm({
      values: filter !== null ? filter : initialState,
    });
  }, [filter]);

  const transformNullValues = useCallback(
    (
      values: FilterSubscriptionsFields
    ): Record<keyof FilterSubscriptionsFields, number | undefined> => {
      const { notice, startup } = values;

      return {
        notice: notice !== null && notice && notice > 0 ? notice : undefined,
        startup:
          startup === null && startup && startup > 0 ? startup : undefined,
      };
    },
    []
  );

  const handleFilter = useCallback((values: FilterSubscriptionsFields) => {
    const listStartup = makeReduxListApplication();

    const { notice, startup } = transformNullValues(values);

    listStartup.list({
      query: {
        notice,
        startup,
      },
    });
    setFilter(() => values);
    onClose();
  }, []);

  const handlePartialFilterOnBlur = useCallback(
    async (values: FilterSubscriptionsFields) => {
      try {
        const listStartup = makeRemoteListApplication();

        const { notice, startup } = transformNullValues(values);

        const response = await listStartup.list({
          query: {
            notice,
            startup,
          },
        });

        setNumberOfResults(() => response.metadata?.total ?? null);
      } catch (error) {
        setNumberOfResults(null);
      }
    },
    []
  );

  const handleRestoreDefault = useCallback(() => {
    const listStartup = makeReduxListStartup();
    listStartup.list({});

    methods.resetForm({
      values: initialState,
    });

    setNumberOfResults(() => metadata?.total ?? null);
    setFilter(null);
    onClose();
  }, []);

  const value: FilterSubscriptionsContextProps = {
    filter,
    methods,
    numberOfResults,
    handleFilter,
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    isOpen,
    onOpen,
    onClose: handleOnClose,
  };

  return (
    <FilterSubscriptionsContext.Provider value={value}>
      {children}
    </FilterSubscriptionsContext.Provider>
  );
};

export const useFilterSubscriptions = (): FilterSubscriptionsContextProps => {
  const context = useContext(FilterSubscriptionsContext);
  if (!context) {
    throw new Error(
      'useFilterStartups must be used within a FilterStartupsProvider'
    );
  }
  return context;
};
