import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Spacer } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { FilterFinancialsProvider } from '~/presentation/context/filters/finance';
import { FilterNoticesProvider } from '~/presentation/context/filters/notices';
import { FilterStartupsProvider } from '~/presentation/context/filters/startups';
import { FilterSubscriptionsProvider } from '~/presentation/context/filters/subscriptions';
import { FilterUsersProvider } from '~/presentation/context/filters/users';
import { withAccess } from '~/presentation/hooks/acess';
import ButtonDownloadReport from '../ButtonDownloadReport';
import ButtonRegister from '../ButtonRegister';
import FilterFinancials from '../Modals/Filter/Financials';
import FilterNotices from '../Modals/Filter/Notices';
import FilterStartups from '../Modals/Filter/Startups';
import FilterSubscriptions from '../Modals/Filter/Subscriptions';
import FilterUsers from '../Modals/Filter/Users';
import ReportGenerator from '../ReportGenerator';
import Input from '../UI/Input';

interface ownProps {
  active?: string;
  buttonText?: string;
  setActive?: (
    value: 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
  ) => void;
  setOpen?: (value: string) => void;
  tabs?: { title: string; resources?: string[] }[];
  selectFilter?: React.ReactNode;
  filterByName?: string;
  setFilterByName?: (value: string) => void;
  changePath?: (name: string, id: number) => void;
  resourcers?: string[];
  actions?: string[];
  action: () => void;
  children?: React.ReactNode;
  canRegister?: boolean;
  searchPlaceholder?: string;
  showDownloadReportButton?: boolean;
  setFilter?: (value: string[]) => void;
}

const TabItem = withAccess(
  ({
    tab,
    active,
    setActive,
    changePath,
  }: {
    tab: string;
    active?: string;
    setActive?: (
      value: 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
    ) => void;
    changePath?: (name: string, id: number) => void;
  }) => {
    return (
      <Flex
        h='60px'
        key={tab}
        align='center'
        color={active === tab ? '2px solid  #222222' : 'none'}
        textTransform='capitalize'
        fontWeight='bold'
        borderBottom={active === tab ? '3px solid  #222222' : 'none'}
        opacity={active === tab ? '1' : '0.5'}
        onClick={() => {
          if (setActive) {
            if (active === tab) changePath && changePath(tab, 0);
            else
              setActive(
                tab as 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
              );
          }
        }}
        cursor='pointer'
      >
        <Box textTransform={'capitalize'}>{tab.toLowerCase()}</Box>
      </Flex>
    );
  }
);

const Tab = ({
  active,
  buttonText,
  setActive,
  tabs,
  setFilterByName,
  changePath,
  resourcers,
  actions,
  action,
  canRegister = true,
  searchPlaceholder,
  showDownloadReportButton = false,
  setFilter,
}: ownProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOnClose = () => {
    return setIsOpen(false);
  };

  const renderFilterButton = useMemo(() => {
    const module = useLocation().pathname.split('/')[1];
    if (!module.trim()) return null;

    switch (module) {
      case 'startups':
        return (
          <FilterStartupsProvider>
            <FilterStartups
              setFilter={setFilter}
              showDownloadReportButton={showDownloadReportButton}
            />
          </FilterStartupsProvider>
        );
      case 'usuarios':
        return (
          <FilterUsersProvider>
            <FilterUsers showDownloadReportButton={showDownloadReportButton} />
          </FilterUsersProvider>
        );
      case 'editais':
        return (
          <FilterNoticesProvider>
            <FilterNotices
              showDownloadReportButton={showDownloadReportButton}
            />
          </FilterNoticesProvider>
        );
      case 'inscricoes':
        return (
          <FilterSubscriptionsProvider>
            <FilterSubscriptions
              showDownloadReportButton={showDownloadReportButton}
            />
          </FilterSubscriptionsProvider>
        );
      case 'orcamento':
        return (
          <FilterFinancialsProvider>
            <FilterFinancials
              showDownloadReportButton={showDownloadReportButton}
            />
          </FilterFinancialsProvider>
        );
      default:
        return null;
    }
  }, [useLocation().pathname]);

  return (
    <Flex
      h={{ base: 'auto', lg: `${tabs ? 'auto' : 'auto'}` }}
      px='3'
      border='1px solid #cfcfcf'
      align='center'
      justify='space-between'
      direction={{
        base: 'column-reverse',
        lg: `${tabs ? 'column-reverse' : 'row'}`,
        xl: 'row',
      }}
      gap={{ base: '10px', lg: `${tabs ? '10px' : '0'}` }}
      p={{
        base: `15px 15px ${tabs ? '0' : '15px'} 15px`,
        xl: `${tabs ? '0' : '15px'} 15px`,
      }}
      overflow={'hidden'}
      bg={'white.1'}
    >
      {!tabs && (
        <Flex
          w={'100%'}
          gap='2'
          justify='space-between'
          bg={'white.1'}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Input
            id='search'
            name='search'
            placeholder={searchPlaceholder}
            w={{ base: '100%', xl: '50%' }} // Adjust width based on screen size
            borderColor={'#9C9C9C'}
            leftIcon={
              <SearchIcon color='#60656D' w={'18px'} h={'18px'} mt={'-4px'} />
            }
            onChange={(e) => {
              setFilterByName && setFilterByName(e.target.value);
            }}
          />
          <HStack spacing='3' w={{ base: '100%', md: 'auto' }}>
            {renderFilterButton}
            {showDownloadReportButton && (
              <ButtonDownloadReport
                action={() => {
                  setIsOpen(true);
                }}
              />
            )}
            {canRegister && (
              <ButtonRegister
                showDownloadReportButton={showDownloadReportButton}
                actions={actions}
                resourcers={resourcers}
                action={action}
                text={buttonText}
                disabled={!canRegister}
              />
            )}
          </HStack>
        </Flex>
      )}
      {tabs && (
        <HStack
          spacing={{ base: '4', xs: '4', sm: '8' }}
          w={{ base: '100%', xl: 'auto' }}
          justify='start'
        >
          {tabs.map((tab) => (
            <TabItem
              key={tab.title}
              tab={tab.title}
              active={active}
              setActive={setActive}
              changePath={changePath}
              actions={tab.resources}
              resourcers={tab.resources}
            />
          ))}
        </HStack>
      )}

      {tabs && (
        <Flex
          gap='2'
          justify='space-between'
          w={{ base: '100%', xl: 'auto' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Input
            id='search'
            name='search'
            placeholder={searchPlaceholder}
            w={{ base: '100%', xl: '280px' }}
            h={'36px'}
            borderColor={'#9C9C9C'}
            leftIcon={
              <SearchIcon color='#60656D' w={'18px'} h={'18px'} mt={'-4px'} />
            }
            onChange={(e) => {
              setFilterByName && setFilterByName(e.target.value);
            }}
          />
          {tabs && <Spacer />}
          <HStack spacing='2'>
            {renderFilterButton}
            {showDownloadReportButton && (
              <ButtonDownloadReport
                action={() => {
                  setIsOpen(true);
                }}
              />
            )}
            {canRegister && (
              <ButtonRegister
                showDownloadReportButton={showDownloadReportButton}
                actions={actions}
                resourcers={resourcers}
                action={action}
                text={buttonText}
                disabled={!canRegister}
              />
            )}
          </HStack>
        </Flex>
      )}
      <ReportGenerator isOpen={isOpen} onClose={handleOnClose} />
    </Flex>
  );
};

export default Tab;
