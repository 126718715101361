import { call, put } from 'redux-saga/effects';
import {
  listFailed,
  listSuccess,
  setMaxMinValues,
} from '~/data/store/reducer/accountability/actions/list';
import { iActionList } from '~/domain/interfaces/redux/accountability/list';
import { ListAccountability } from '~/domain/usecases/accountability/remote/ListAccountability';
import { makeRemoteListAccountability } from '~/main/factories/usecases/accountability/ListAccountabilityFactory';

export function* onList(action: iActionList) {
  const remoteListAccountability = makeRemoteListAccountability();

  try {
    const response: ListAccountability.Model = yield call(
      remoteListAccountability.list,
      action.payload
    );
    yield put(
      listSuccess({
        ...response,
      })
    );

    const hasQuery = Object.keys(action.payload).length > 0;

    if (!hasQuery) {
      yield put(setMaxMinValues());
    }
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
