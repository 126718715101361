/* eslint-disable no-console */
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStartup, iStore } from '~/domain/interfaces/models';
import FormAddMemberToStartup from '~/presentation/components/Form/FormAddMemberToStartup/FormAddMemberToStartup';
import { Button } from '~/presentation/components/UI/Button';
import Form from '~/presentation/components/UI/FormFormik';
import { Typography } from '~/presentation/components/UI/Typography';
import { schemaAddMemberToStartup } from '~/validators/AddMemberToStartup/AddMemberToStartup';

interface iNewMember {
  users: iUser[];
}

interface iUser {
  value: string;
  label: string;
  role: string;
  avatar: string;
  email: string;
}

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const AddMemberToStartup = ({
  isOpen,
  onClose,
  startup,
}: ownProps): JSX.Element => {
  const { initial, validators } = schemaAddMemberToStartup;
  const [isLoading, setIsLoading] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const [selectedUserId, setSelectedUserId] = React.useState<string>('');
  const [usersSet, setUsersSet] = React.useState<iUser[]>([]);
  const { records } = useSelector((store: iStore) => store.user);

  const {
    values: valuesMainForm,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<iNewMember>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('>>>values', valuesMainForm.users);

    const arrayUsers =
      records?.map((user) => ({
        value: user.id.toString(),
        label: user.fullname,
        role: user.role.name,
        avatar: user.avatar,
        email: user.email,
      })) ?? [];

    const arrayUsersFiltered = arrayUsers
      .filter((user) => user.role === 'MEMBER' || user.role === 'LEADER')
      .filter((user) =>
        valuesMainForm.users?.every((u) => u.value !== user.value)
      )
      .filter((user) =>
        startup?.members?.every((u) => `${u.user.id}` !== user.value)
      );

    const arrayOptions = Array.from(
      new Map(arrayUsersFiltered.map((item) => [item.value, item])).values()
    );

    setUsersSet([...arrayOptions]);
  }, [valuesMainForm.users, records, startup?.members]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      if (!startup) return;
      setFieldValue(
        'users',
        valuesMainForm?.users?.concat([
          {
            value: values.user.value,
            label: values.user.label,
            role: values.role,
            avatar: values.user.avatar,
            email: values.user.email,
          },
        ])
      ).finally(() => {
        setSelectedUserId('');
        resetForm();
        onClose();
      });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => onCloseModal()}
        isCentered={!isMobile}
        size={{ base: 'full', md: 'sm' }}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent
          bg='#FDFDFD'
          minH={'auto'}
          position={{ base: 'fixed', md: 'inherit' }}
          bottom={{ base: '0', md: 'auto' }}
          marginTop='auto'
          borderTopRadius={{ base: '10px', md: '10px' }}
          borderBottomRadius={{ base: 'none', md: '10px' }}
        >
          <ModalHeader p='20px 24px' whiteSpace='nowrap'>
            <Flex
              flexDirection='row'
              justifyContent={'space-between'}
              alignItems='center'
            >
              <Typography variant='h3_Inter_Bold_18px'>
                Adicionar à equipe
              </Typography>
              <ModalCloseButton
                sx={{
                  fontSize: '14px',
                }}
                position={'inherit'}
                color={'#222222'}
              ></ModalCloseButton>
            </Flex>
          </ModalHeader>
          <ModalBody p='0px 24px 20px 24px'>
            <Box maxH={{ base: '625px', md: 'auto' }}>
              {startup && (
                <Form value={formik}>
                  <FormAddMemberToStartup
                    startup={startup}
                    setSelectedUserId={setSelectedUserId}
                    selectedUserId={selectedUserId}
                    usersSet={usersSet}
                  />
                </Form>
              )}
            </Box>
          </ModalBody>
          <ModalFooter
            p='20px 24px'
            justifyContent='space-between'
            alignItems='center'
            gap={{ base: '69px', md: '10px' }}
            borderTop='1px solid #CECECE'
          >
            <>
              <Button
                width={'100%'}
                variant='primary'
                onClick={() => {
                  formik.handleSubmit();
                }}
                fontWeight='medium'
                size='md'
                disabled={!formik.isValid}
                isLoading={isLoading}
              >
                <Typography variant='h7_Inter_Medium_14px'>
                  Adicionar
                </Typography>
              </Button>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddMemberToStartup;
