import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListStartup as UsecaseRemoteListStartup } from '~/domain/usecases/startup/remote';
import { ListStartup as UsecaseReduxListStartup } from '~/domain/usecases/startup/redux';

import { RemoteListStartup } from '~/data/repository/startup';
import { ReduxListStartup } from '~/data/store/reducer/startup/usecases';

/**
 * send request via API.
 */
export const makeRemoteListStartup = (): UsecaseRemoteListStartup =>
  new RemoteListStartup(makeApiUrl('/startups/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListStartup = (): UsecaseReduxListStartup =>
  new ReduxListStartup();
