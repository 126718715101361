import { Avatar, Flex, Link, Text } from '@chakra-ui/react';
import { iEvent } from '~/domain/interfaces/models/Event';
import IconEventTypeBullet from '~/presentation/base/icons/iconEventTypeBullet';
import GenericModalLayout from '~/presentation/components/Modals/GenericModalLayout';
import { getColorByEventType } from '~/utils/getColorByEventType';
import getDate, { getTime } from '~/utils/getDate';
import getEventTypeString from '~/utils/getEventTypeString';
import getTextBasedOnEventType from '~/utils/getTextBasedOnEventType';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  event?: iEvent['records'][0];
}

const DetailsEvent = ({ isOpen, onClose, event }: ownProps) => {
  return (
    <GenericModalLayout
      isOpen={isOpen}
      onClose={() => onClose()}
      headerTitle='Detalhes do Evento'
      modalSize='tiny'
      isPartitioned={true}
      centerContent={
        <Flex flexDirection={'row'} gap='32px'>
          <Flex flexDirection={'column'} gap='20px'>
            <Flex key='' flexDirection={'column'} gap='8px'>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Nome do Evento
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#60656D'
              >
                {event?.name}
              </Text>
            </Flex>
            <Flex flexDirection={'row'} gap={'40px'}>
              <Flex
                flexDirection={'column'}
                gap={'9px'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                textAlign={'end'}
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='sm'
                  color='#303950'
                >
                  Tipo
                </Text>
                <Flex flexDir={'row'} gap={'5px'} alignItems={'center'}>
                  {event?.type && (
                    <>
                      <IconEventTypeBullet
                        color={getColorByEventType(event?.type)}
                      ></IconEventTypeBullet>
                      <Text
                        fontFamily='Inter'
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize='sm'
                        color='#60656D'
                        textAlign={'center'}
                      >
                        {getEventTypeString(event?.type)}
                      </Text>
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex
                flexDirection={'column'}
                gap={'9px'}
                justifyContent={'space-between'}
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='sm'
                  color='#303950'
                >
                  Data
                </Text>
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='400'
                  fontSize='sm'
                  color='#60656D'
                >
                  {getDate(event?.date)}
                </Text>
              </Flex>
              <Flex
                flexDirection={'column'}
                gap={'9px'}
                justifyContent={'space-between'}
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='sm'
                  color='#303950'
                >
                  Horário
                </Text>
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='400'
                  fontSize='sm'
                  color='#60656D'
                >
                  {getTime(event?.date)}
                </Text>
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} gap={'9px'}>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Link associado
              </Text>
              <Link color='#1997F3' fontSize='sm' href={event?.link} isExternal>
                {event?.link}
              </Link>
            </Flex>
            {event?.type === 'MENTORING' || event?.type === 'DELIVERY' ? (
              <Flex flexDirection={'column'} gap={'20px'}>
                <Flex flexDirection={'column'} gap={'9px'}>
                  <Text
                    fontFamily='Inter'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize='sm'
                    color='#303950'
                  >
                    Edital associado
                  </Text>
                  <Text
                    fontFamily='Inter'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='sm'
                    color='#60656D'
                  >
                    {(event?.notice?.code && event?.notice?.title && (
                      <>{`Nº ${event?.notice?.code}-${event?.notice?.title}`}</>
                    )) ??
                      ''}
                  </Text>
                </Flex>
                {event?.type === 'DELIVERY' && (
                  <Flex flexDirection={'column'} gap={'9px'}>
                    <Text
                      fontFamily='Inter'
                      fontStyle='normal'
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                    >
                      Etapa associada
                    </Text>
                    <Text
                      fontFamily='Inter'
                      fontStyle='normal'
                      fontWeight='400'
                      fontSize='sm'
                      color='#60656D'
                    >
                      {(event?.step?.name && <>{` ${event?.step?.name}`}</>) ??
                        ''}
                    </Text>
                  </Flex>
                )}
              </Flex>
            ) : (
              <></>
            )}
            <Flex flexDirection={'column'} gap={'9px'}>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Descrição
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                size={'xs'}
                variant={'unstyled'}
                color='#60656D'
                id={''}
                maxHeight={'60px'}
                maxW={'fit-content'}
                overflowX='auto'
                overflowY='auto'
              >
                {event?.description ?? ''}
              </Text>
            </Flex>
            {((event?.type && event?.type === 'MEETING') ||
              event?.type === 'MENTORING') && (
              <Flex
                flexDirection={'column'}
                gap={'9px'}
                maxW={'350px'}
                marginBottom={'20px'}
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='sm'
                  color='#303950'
                >
                  {getTextBasedOnEventType(event?.type ?? '')}
                </Text>
                {event?.responsable && (
                  <Flex
                    flexDirection={'row'}
                    gap={'12px'}
                    alignItems={'center'}
                  >
                    <Text
                      fontFamily='Inter'
                      fontStyle='normal'
                      fontWeight='400'
                      fontSize='sm'
                      color='#60656D'
                    >
                      {
                        <Avatar
                          name={event?.responsable?.fullname ?? ''}
                          src={''} // src={event?.responsable?.avatar ?? ''}
                        />
                      }
                    </Text>
                    {event?.type === 'MEETING' ||
                    event?.type === 'MENTORING' ? (
                      <Flex flexDirection={'column'} maxHeight={'40px'}>
                        <Text
                          fontFamily='Inter'
                          fontStyle='normal'
                          fontWeight='700'
                          fontSize='sm'
                          color='#60656D'
                        >
                          {event?.responsable?.fullname ?? ''}
                        </Text>

                        <Text
                          fontFamily='Inter'
                          fontStyle='normal'
                          fontWeight='400'
                          fontSize='sm'
                          color='#60656D'
                          maxW={'280px'}
                          whiteSpace={'pre-wrap'}
                        >
                          {event?.responsable?.email ?? ''}
                        </Text>
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Flex>
                )}
              </Flex>
            )}
            <></>
          </Flex>
        </Flex>
      }
    ></GenericModalLayout>
  );
};

export default DetailsEvent;
