import { useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import {
  makeReduxListAccountability,
  makeRemoteListAccountability,
} from '~/main/factories/usecases/accountability/ListAccountabilityFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { FilterContextProps, FilterProviderProps } from './types';

export type FilterFinancialsFieldsType = {
  initialValue: number;
  finalValue: number;
  notice: number;
  startup: number;
  financial: number[];
};

export type FilterFinancialsFields = Partial<FilterFinancialsFieldsType>;

type FilterFinancialsContextProps = FilterContextProps<FilterFinancialsFields>;

const FilterFinancialsContext = createContext<
  FilterFinancialsContextProps | undefined
>(undefined);

const initialValues: FilterFinancialsFields = {
  initialValue: undefined,
  finalValue: undefined,
  notice: undefined,
  startup: undefined,
  financial: undefined,
};

export const FilterFinancialsProvider: React.FC<FilterProviderProps> = ({
  children,
}) => {
  const [filter, setFilter] = useState<FilterFinancialsFields | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { metadata } = useSelector((store: iStore) => store.accountability);

  const [numberOfResults, setNumberOfResults] = useState<number | null>(
    () => metadata?.total ?? null
  );

  const methods = useFormik<FilterFinancialsFields>({
    initialValues: filter ?? initialValues,
    onSubmit: (values) => handleFilterFinancials(values),
    validateOnChange: true,
  });

  const transformNullValues = useCallback(
    (
      values: FilterFinancialsFields
    ): Record<
      Exclude<keyof FilterFinancialsFields, 'financial'>,
      number | undefined
    > => {
      const { notice, startup, ...rest } = values;

      return {
        notice: notice !== null && notice && notice > 0 ? notice : undefined,
        startup:
          startup !== null && startup && startup > 0 ? startup : undefined,
        initialValue:
          values.financial &&
          values.financial?.[0] !== null &&
          values.financial?.[0] > 0
            ? values.financial?.[0]
            : undefined,
        finalValue:
          values.financial &&
          values.financial?.[1] !== null &&
          values.financial?.[1] > 0
            ? values.financial?.[1]
            : undefined,
      };
    },
    []
  );

  const handleFilterFinancials = useCallback(
    (values: FilterFinancialsFields) => {
      const listStartup = makeReduxListAccountability();

      const { initialValue, finalValue, notice, startup } =
        transformNullValues(values);

      listStartup.list({
        query: {
          initialValue,
          finalValue,
          notice,
          startup,
        },
      });
      setFilter(() => values);
      onClose();
    },
    []
  );

  const hasFilterActive = useMemo((): boolean => {
    return filter !== null;
  }, [filter]);

  const handleOnClose = useCallback(() => {
    onClose();
    methods.resetForm({
      values: filter !== null ? filter : initialValues,
    });
  }, [filter]);

  const handlePartialFilterOnBlur = useCallback(
    async (values: FilterFinancialsFields) => {
      try {
        const listStartup = makeRemoteListAccountability();

        const { initialValue, finalValue, notice, startup } =
          transformNullValues(values);

        const response = await listStartup.list({
          query: {
            initialValue,
            finalValue,
            notice,
            startup,
          },
        });

        setNumberOfResults(() => response.metadata.items);
      } catch (error) {
        setNumberOfResults(null);
      }
    },
    []
  );

  const handleRestoreDefault = useCallback(() => {
    const listStartup = makeReduxListStartup();
    listStartup.list({});

    methods.resetForm({
      values: initialValues,
    });

    setNumberOfResults(() => metadata?.total ?? null);
    setFilter(null);
    onClose();
  }, []);

  const value: FilterFinancialsContextProps = {
    filter,
    methods,
    numberOfResults,
    handleFilter: handleFilterFinancials,
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    isOpen,
    onOpen,
    onClose: handleOnClose,
  };

  return (
    <FilterFinancialsContext.Provider value={value}>
      {children}
    </FilterFinancialsContext.Provider>
  );
};

export const useFilterFinancials = (): FilterFinancialsContextProps => {
  const context = useContext(FilterFinancialsContext);
  if (!context) {
    throw new Error(
      'useFilterFinancials must be used within a FilterFinancialsProvider'
    );
  }
  return context;
};
