import {
  Avatar,
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iUser } from '~/domain/interfaces/models/User';
import {
  IconDelete,
  IconDisconnect,
  IconEdit,
  IconLinkUser,
  IconView,
} from '~/presentation/base/icons';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import ConflictError from '~/presentation/components/Modals/Confirmation/ConflictError';
import DeleteUserSuccess from '~/presentation/components/Modals/Confirmation/DeleteUserSuccess';
import EditUserSuccess from '~/presentation/components/Modals/Confirmation/EditUserSuccess';
import RegisterUserSuccess from '~/presentation/components/Modals/Confirmation/RegisterUserSuccess ';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeactivateUser from '~/presentation/components/Modals/user/DeactivateUser';
import DeleteUser from '~/presentation/components/Modals/user/DeleteUser';
import DetailsUser from '~/presentation/components/Modals/user/DetailsUser';
import EditMember from '~/presentation/components/Modals/user/EditMember';
import LinkUser from '~/presentation/components/Modals/user/LinkUser';
import RegisterUser from '~/presentation/components/Modals/user/RegisterUser';
import Tab from '~/presentation/components/Tab';
import { Select } from '~/presentation/components/UI/NewSelect';
import { FilterUsersProvider } from '~/presentation/context/filters/users';
import { useDebounce } from '~/presentation/hooks/useDebounce';
import { getRoleByName } from '~/utils/getRoleByName';

const Team = (): JSX.Element => {
  const [active, setActive] = React.useState('STARTUPS');
  const [open, setOpen] = React.useState('');
  const openRegisterUser = () => {
    setOpen('REGISTER');
  };
  const [selectedUser, setSelectedUser] = React.useState<
    iUser['records'][0] | undefined
  >(undefined);
  const { records } = useSelector((store: iStore) => store.user);
  const userLogged = useSelector((store: iStore) => store.auth);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const { selectedStartup: workspaceSelectedStartup } = useSelector(
    (store: iStore) => store.auth
  );

  const searchValueDebounced = useDebounce(searchValue);

  const roleOptionsByUserRole = React.useMemo(() => {
    switch (userLogged.user?.role_.name) {
      case 'ADMIN':
        return [
          { value: 'SUPERVISOR', label: 'Supervisor' },
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      case 'SUPERVISOR':
        return [
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      case 'LEADER':
        return [
          { value: 'LEADER', label: 'Gestor' },
          { value: 'MEMBER', label: 'Membro' },
        ];
      default:
        return [{ value: '', label: '' }];
    }
  }, [userLogged.user?.role_.name]);

  const users = useMemo(() => {
    if (searchValueDebounced) {
      return records.filter((user) =>
        user.fullname.toLowerCase().includes(searchValueDebounced.toLowerCase())
      );
    }
    return records;
  }, [searchValueDebounced, records]);

  return (
    <Box
      flex='1'
      my='6'
      mx={{ base: 'auto', md: '8' }}
      h='calc(100% - 136px)'
      position='relative'
      display='flex'
      flexDirection='column'
    >
      <FilterUsersProvider>
        <Tab
          active={active}
          setActive={setActive}
          tabs={[
            { title: 'INCUBADORA', resources: ['ADMIN', 'SUPERVISOR'] },
            { title: 'MENTORES', resources: ['ADMIN', 'SUPERVISOR'] },
            { title: 'STARTUPS', resources: ['ADMIN', 'SUPERVISOR'] },
          ]}
          filterByName={searchValue}
          setFilterByName={setSearchValue}
          buttonText='Novo Integrante'
          searchPlaceholder='Pesquisar usuários...'
          actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
          resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
          action={() => setOpen('REGISTER')}
        />
      </FilterUsersProvider>
      <TableContainer
        flex='1'
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        borderBottom='1px solid #cfcfcf'
        borderBottomRadius={8}
        overflowY='auto'
      >
        <Table variant='simple' colorScheme='white'>
          <Thead position='sticky' top={0} zIndex={5} height='50px'>
            <Tr bg='white.3'>
              <Th fontSize='sm'>ID</Th>
              <Th fontSize='sm' textTransform={'capitalize'}>
                Nome do Usuário
              </Th>
              {active === 'STARTUPS' && (
                <Th fontSize='sm' textTransform={'capitalize'}>
                  Startup Associada
                </Th>
              )}
              {active !== 'STARTUPS' && (
                <Th fontSize='sm' textTransform={'capitalize'}>
                  {active === 'MENTOR' ? 'Especialidade' : 'Cargo'}
                </Th>
              )}
              <Th fontSize='sm' textTransform={'capitalize'}>
                Permissão
              </Th>
              <Th fontSize='sm' textTransform={'capitalize'}>
                Ações
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {users
              .filter((item) => {
                if (item.id === userLogged.user?.id) return false;

                switch (active) {
                  case 'STARTUPS':
                    return (
                      (item.role.name === 'LEADER' ||
                        item.role.name === 'MEMBER') &&
                      item.situation === 'REGULAR'
                    );

                  case 'MENTORES':
                    return item.role.name === 'MENTOR';

                  case 'INCUBADORA':
                    return (
                      item.role.name === 'SUPERVISOR' ||
                      item.role.name === 'ADMIN'
                    );
                }
              })
              .filter((startup) => {
                if (
                  userLogged?.user?.role_?.name === 'MEMBER' ||
                  userLogged?.user?.role_?.name === 'LEADER'
                ) {
                  return startup.startup?.id === workspaceSelectedStartup?.id;
                } else {
                  return true;
                }
              })

              .map((item, index) => (
                <Tr fontSize={14} fontWeight={400} color='#747C86' key={index}>
                  <Td>{item.id}</Td>
                  <Td>
                    <HStack spacing='4'>
                      <Avatar name={item.fullname} src={item?.avatar} />
                      <Box fontSize='sm'>
                        <Text fontWeight='semibold'>{item.fullname}</Text>
                        <Text fontSize='xs'>{item.email}</Text>
                      </Box>
                    </HStack>
                  </Td>
                  {active === 'STARTUPS' && (
                    <Td>
                      <HStack spacing='4'>
                        <Avatar name={item.startup?.logo} />
                        <Box fontSize='sm'>
                          <Text fontWeight='semibold'>
                            {item.startup?.name}
                          </Text>
                          {/* Atualizar formato de endereço */}
                          {/* <Text fontSize='xs'>{'João Pessoa - PB'}</Text> */}
                        </Box>
                      </HStack>
                    </Td>
                  )}
                  {active !== 'STARTUPS' && (
                    <Td>{item.members[0]?.job ?? 'Não informado!'}</Td>
                  )}
                  <Td>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        role: {
                          value: item.role.name,
                          label: getRoleByName(item.role.name),
                        },
                      }}
                      onSubmit={async (values) => {
                        await axios.patch(
                          `/api/startups/${workspaceSelectedStartup}/${item.id}`,
                          {
                            role: values.role.value,
                          }
                        );
                      }}
                    >
                      {({ values, setFieldValue, handleSubmit }) => {
                        const handleRoleChange = (
                          selectedOption: any,
                          actionMeta: any
                        ) => {
                          setFieldValue('role', selectedOption.value);
                          handleSubmit();
                        };

                        return (
                          <Form name='role'>
                            <Select
                              name='role'
                              value={values.role}
                              options={roleOptionsByUserRole}
                              onChange={(newValue: any, actionMeta: any) =>
                                handleRoleChange(newValue, actionMeta)
                              }
                              isDisabled={true} // or controlled by isMajor if needed
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </Td>

                  <Td>
                    <HStack spacing='4'>
                      <ActionButton
                        onClick={() => {
                          setOpen('DETAILS'), setSelectedUser(item);
                        }}
                      >
                        <IconView />
                      </ActionButton>
                      {active === 'MENTORES' && (
                        <ActionButton
                          onClick={() => {
                            setOpen('LINK'), setSelectedUser(item);
                          }}
                        >
                          <IconLinkUser />
                        </ActionButton>
                      )}
                      <ActionButton
                        actions={[
                          'ADMIN',
                          'SUPERVISOR',
                          ...[item.role?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        resourcers={[
                          'ADMIN',
                          'SUPERVISOR',
                          ...[item.role?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        onClick={() => {
                          setOpen('EDIT'), setSelectedUser(item);
                        }}
                      >
                        <IconEdit />
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => setOpen('DEACTIVATE')}
                        disabled
                      >
                        <IconDisconnect />
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN']}
                        resourcers={[
                          'ADMIN',
                          ...[item.role?.name === 'MEMBER' ? 'LEADER' : ''],
                        ]}
                        onClick={() => {
                          setOpen('DELETE'), setSelectedUser(item);
                        }}
                      >
                        <IconDelete />
                      </ActionButton>
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <RegisterUser isOpen={open === 'REGISTER'} onClose={() => setOpen('')} />

      <EditMember
        id={selectedUser?.id}
        isOpen={open === 'EDIT'}
        onClose={() => {
          setSelectedUser(undefined);
          setOpen('');
        }}
      />

      <DetailsUser
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        user={selectedUser}
      ></DetailsUser>

      <LinkUser
        isOpen={open === 'LINK'}
        onClose={() => setOpen('')}
        user={selectedUser}
      ></LinkUser>

      <DeactivateUser
        isOpen={open === 'DEACTIVATE'}
        onClose={() => setOpen('')}
      />

      <DeleteUser
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        user={selectedUser}
        id={selectedUser?.id}
      />
      <RegisterUserSuccess onOpenRegister={openRegisterUser} />
      <EditUserSuccess />
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteUserSuccess user={selectedUser}></DeleteUserSuccess>
      <ConflictError customText='Já existe uma conta associada a este e-mail'></ConflictError>
    </Box>
  );
};

export default Team;
