import { call, put } from 'redux-saga/effects';
import {
  listFailed,
  listSuccess,
  setMaxMinValues,
} from '~/data/store/reducer/notice/actions/list';
import { iActionList } from '~/domain/interfaces/redux/notice/list';
import { ListNotice } from '~/domain/usecases/notice/remote/ListNotice';
import { makeRemoteListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';

export function* onList(action: iActionList) {
  const remoteListNotice = makeRemoteListNotice();

  try {
    const response: ListNotice.Model = yield call(
      remoteListNotice.list,
      action.payload
    );
    yield put(
      listSuccess({
        ...response,
      })
    );

    const hasQuery = Object.keys(action.payload).length > 0;

    if (!hasQuery) {
      yield put(setMaxMinValues())
    }
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
