import { iNotice } from '~/domain/interfaces/models/Notice';
import { NoticeTypes } from '~/domain/interfaces/redux/notice/types';
import { NoticeActions } from './actions';

export const initialState: iNotice = {
  loading: false,
  records: [],
  value: {
    max: 0,
    min: 0,
  },
  vacancies: {
    max: 0,
    min: 0,
  },
};

const reducer = (state = initialState, action: NoticeActions): iNotice => {
  switch (action.type) {
    case NoticeTypes.LIST: {
      return { ...state, loading: true };
    }
    case NoticeTypes.LIST_FAILED:
      return { ...state, loading: false };
    case NoticeTypes.LIST_SUCCESS: {
      const { vacancies, value } = state;
      return { ...action.payload, vacancies, value, loading: false };
    }
    case NoticeTypes.SET_MAX_MIN_VALUES: {
      const { records } = state;
      const maxValue = records.reduce((acc, record) => {
        return record.value > acc ? record.value : acc;
      }, 0);
      const minValue = records.reduce((acc, record) => {
        return record.value < acc ? record.value : acc;
      }, maxValue);

      const maxVacancies = records.reduce((acc, record) => {
        return record.vacancies > acc ? record.vacancies : acc;
      }, 0);

      const minVacancies = records.reduce((acc, record) => {
        return record.vacancies < acc ? record.vacancies : acc;
      }, maxVacancies);

      return {
        ...state,
        loading: false,
        value: {
          max: maxValue,
          min: minValue,
        },
        vacancies: {
          max: maxVacancies,
          min: minVacancies,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
