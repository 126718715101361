import { HStack, Stack } from '@chakra-ui/react';
import { ComponentProps, ReactNode } from 'react';
import { Slider } from '.';

type SliderFieldProps = ComponentProps<typeof Slider> & {
  name: string;
  value: number[];
  extraContent?: (values: number[]) => ReactNode;
};

export const SliderField = ({ name, value, ...props }: SliderFieldProps) => {
  return (
    <Stack>
      <HStack>{props.extraContent && props.extraContent(value)}</HStack>
      <Slider
        aria-label={['min', 'max']}
        step={50}
        value={value}
        name={name}
        ref={props.ref}
        {...props}
      />
    </Stack>
  );
};
