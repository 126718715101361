import React, { useContext } from 'react';
import WarningModal from '~/presentation/components/Modals/WarningModal';
import { makeRemoteDeleteStartup } from '~/main/factories/usecases/startup/DeleteStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { iStartup } from '~/domain/interfaces/models';
import { Text } from '@chakra-ui/react';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
  id?: number;
}

const DeleteStartup = ({
  isOpen,
  onClose,
  startup,
  id,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Excluir Startup'
      question={
        <Text>
          Tem certeza de que quer excluir a <Text as='span' fontWeight={'medium'}>{startup?.name}</Text>?
        </Text>
      }
      description='Todos os documentos desta startup serão excluídos permanentemente.'
      action={() => {
        id &&
          makeRemoteDeleteStartup()
            .delete({ id })
            .then(() => {
              makeReduxListStartup().list({ body: { limit: 999 } });
              showConfirmModal('DELETE_STARTUP');
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              onClose();
            });
      }}
      actionText='Excluir'
      actionTextLoading='Excluindo'
    />
  );
};

export default DeleteStartup;
