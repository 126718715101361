import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import Accountability from '~/presentation/pages/accountability';
import { makeReduxListAccountability } from '../../usecases/accountability/ListAccountabilityFactory';
import { makeReduxListApplication } from '../../usecases/application/ListApplicationFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';

export const makeAccountabilityFactory: React.FC = () => {
  const { user, startups, selectedStartup } = useSelector(
    (store: iStore) => store.auth
  );

  useEffect(() => {
    makeReduxListApplication().list({
      query: {
        ...(user?.role_.name === 'LEADER' && startups?.length
          ? { startup: selectedStartup?.id }
          : {}),
      },
    });
    makeReduxListAccountability().list({});
    makeReduxListNotice().list({});
    makeReduxListStartup().list({});
  }, []);
  return <Accountability />;
};
