import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  iActionSetMaxMinValues,
  NoticeTypes,
} from '~/domain/interfaces/redux/notice/list';
import { ListNotice } from '~/domain/usecases/notice/redux/ListNotice';

export const listRequest = (payload: ListNotice.Params): iActionList => ({
  type: NoticeTypes.LIST,
  payload,
});

export const listSuccess = (params: ListNotice.Model): iActionListSuccess => ({
  type: NoticeTypes.LIST_SUCCESS,
  payload: params,
});

export const setMaxMinValues = (): iActionSetMaxMinValues => ({
  type: NoticeTypes.SET_MAX_MIN_VALUES,
});

export const listFailed = (): iActionListFailed => ({
  type: NoticeTypes.LIST_FAILED,
});
