import { Box, Stack, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { iApplication } from '~/domain/interfaces/models/Application';
import IconSubmitionRejected from '~/presentation/base/icons/iconSubmitionRejected';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';

interface ownProps {
  application?: iApplication['records'][0];
}

const SubmitionRejected = ({ application }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'APPLICATION_REJECTED'} //active === 'APPLICATION_REJECTED'
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconSubmitionRejected />}
      title='Inscrição indeferida!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>
              Você optou por{' '}
              <Box as='span' fontWeight={'bold'} color={'#c23322'}>
                {' '}
                indeferir{' '}
              </Box>{' '}
              a inscrição da startup {`"`}
              <b>{`${application?.startup?.fullname}`}</b>
              {`"`} para o Edital nº{' '}
              {`${application?.notice.code} - ${application?.notice.title}`}.
            </Text>
            <Text>
              <Text>
                Lembre-se: você ainda poderá alterar o resultado desta avaliação
              </Text>
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default SubmitionRejected;
