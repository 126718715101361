import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { History } from '~/main/routes';
import { IconRegistrationsNotice } from '~/presentation/base/icons';
import FormRegisterNotice from '~/presentation/components/Form/RegisterNotice/FormRegisterNotice';
import UploadDocs from '~/presentation/components/UploadDocs';
import { schemaRegisterNotice } from '~/validators/RegisterNotice/RegisterNoticeValidator';

const RegistrationNotice = (): JSX.Element => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const { initial, validators } = schemaRegisterNotice;
  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      /* await axios.post(makeApiUrl('/files/upload'), formData).then((res) => {
        const f = res.data.map((el: any) => el.id);

        makeRemoteCreateNotice()
          .create({
            body: {
              code: values.number,
              title: values.identification,
              description: values.object,
              start: datefns.format(new Date(values.start), 'yyyy-MM-dd'),
              end: datefns.format(new Date(values.end), 'yyyy-MM-dd'),
              status: 'string',
              value: Number(values.value),
              vacancies: Number(values.vacancies),
              files: f,
            },
          })
          .then(() => {
            makeReduxListNotice().list({});
            resetForm({ values: initial });
          });
        History.push('/editais');
      }); */
    },
  });

  const cancel = () => {
    formik.resetForm();
    History.back();
  };

  // eslint-disable-next-line no-console
  console.log(formik.isValid, formik.values, formik.errors, formik.touched);

  const handleSubmition = () => {
    formik.handleSubmit();
  };

  const handleLog = () => {
    // eslint-disable-next-line no-console
    console.log('cadastro concluido' + 'e n qtd de steps: ' + step);
  };

  let isUploaded = false;
  if (files.length > 0) {
    isUploaded = true;
  }

  return (
    <Box flex='1' h='calc(100% - 110px)'>
      <Flex justify='space-between' direction='column' h='100%'>
        <Box>
          {step === 1 ? (
            <Flex direction='column'>
              <Stack spacing='4' pl='8' py='8' padding={'32px'}>
                <FormRegisterNotice formik={formik} setFiles={setFiles} />
              </Stack>
            </Flex>
          ) : step === 2 ? (
            <Flex direction='column'>
              <Flex justify='space-between' mx='8'>
                <Text color='#1c294b' fontWeight='bold' fontSize='2xl'>
                  Novo Edital
                </Text>
              </Flex>
              <Stack spacing='4' pl='8' py='8'>
                <Text fontWeight='bold' fontSize='md' mb='1'>
                  Documentos do edital
                </Text>
                oi
                <UploadDocs
                  onChange={(files: File[]) => {
                    setFiles(files);
                  }}
                />
              </Stack>
            </Flex>
          ) : step === 3 ? (
            <Box>
              <Flex
                flexDirection={'column'}
                align='center'
                justify={'space-between'}
                gap='30px'
                paddingTop='176px'
              >
                <IconRegistrationsNotice></IconRegistrationsNotice>
                <Flex
                  flexDirection={'column'}
                  align='center'
                  justify={'center'}
                  gap='5px'
                >
                  <Text color='#303950' fontWeight='bold' fontSize='3xl'>
                    Edital cadastrado!
                  </Text>
                  <Text
                    color='#1C294B'
                    fontWeight='normal'
                    fontSize='1xl'
                    textAlign='center'
                    width='380px'
                  >
                    O edital nº 25/2022 - SEECT-FAPESQ-PB foi inserido no
                    sistema e já está disponível para os demais usuários.
                  </Text>
                </Flex>
                <Button
                  bg='transparent'
                  _hover={{ background: '#D3DCF4' }}
                  _active={{ background: '#B3C5F4' }}
                  border='1.5px solid #303950'
                  border-radius='5px'
                  color='#303950'
                  fontSize={12}
                  onClick={() => 'ir a Lista de Editais'}
                  width='208px'
                  height='40px'
                  padding='10px 20px 10px 20px'
                >
                  <Text
                    color='#303950'
                    fontWeight='500'
                    fontSize='1xl'
                    textAlign='center'
                  >
                    Ir para a Lista de Editais
                  </Text>
                </Button>
              </Flex>
            </Box>
          ) : null}
        </Box>
        {step !== 3 ? (
          <Flex justify='space-between' px='8' py='7'>
            <Button
              variant='outline'
              color='#303950'
              borderColor='#303950'
              onClick={() => {
                step === 1 ? History.push('/editais') : setStep(step - 1);
              }}
            >
              {step === 1 ? 'Cancelar' : 'Voltar'}
            </Button>
            {step !== 2 ? (
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                onClick={() => setStep(step + 1)}
              >
                Avançar
              </Button>
            ) : (
              <Button
                disabled={!isUploaded}
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                onClick={() => (
                  setStep(step + 1), handleSubmition(), handleLog()
                )}
              >
                Concluir cadastro
              </Button>
            )}
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export default RegistrationNotice;
