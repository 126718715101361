import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  TextareaProps,
  Textarea as ChakraInput,
} from '@chakra-ui/react';
import { useField } from 'formik';

interface OwnProps extends TextareaProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  formik?: any;
}

const TextArea = ({
  id,
  name,
  label,
  required,
  formik,
  ...rest
}: OwnProps): JSX.Element => {
  let fieldProps;
  let error: string | undefined;
  let touched: boolean | undefined;

  if (formik) {
    fieldProps = {
      value: formik.values[name],
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };
    error = formik.errors[name];
    touched = formik.touched[name];
  } else {
    const [field, meta] = useField(name);
    fieldProps = field;
    error = meta.error;
    touched = meta.touched;
  }

  return (
    <FormControl isRequired={required} isInvalid={!!(error && touched)}>
      <FormLabel fontWeight='bold' fontSize='sm' mb='2'>
        {label}
      </FormLabel>
      <ChakraInput id={id} {...fieldProps} bg='#F9F8FA' {...rest} />
      {error && touched && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default TextArea;
