import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Flex, Text } from '@chakra-ui/react';
import { IconPrevMonth, IconNextMonth } from '~/presentation/base/icons';
import MaskedInput from 'react-text-mask';
import { Input } from '../NewInput';

type DateInputProps = Omit<ReactDatePickerProps, 'onChange'> & {
  label?: string;
  withMask?: boolean;
};

const DatePickerField: React.FC<DateInputProps> = ({
  name,
  label,
  withMask = true,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name || '');

  const parsedDate = (() => {
    if (!field.value) return null;
    const date = new Date(field.value);
    return isNaN(date.getTime()) ? null : date;
  })();

  const customHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <Flex p='2.5' justify='center' w='100%'>
      <button
        type='button'
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <IconPrevMonth />
      </button>
      <Text
        fontWeight='bold'
        fontSize='sm'
        color='#303950'
        w='calc(100% - 64px)'
      >
        {monthDate.toLocaleString('pt-Br', {
          month: 'long',
          year: 'numeric',
        })}
      </Text>
      <button
        type='button'
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <IconNextMonth />
      </button>
    </Flex>
  );

  return (
    <DatePicker
      {...field}
      {...props}
      selected={parsedDate}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      renderCustomHeader={customHeader}
      customInput={
        <Input
          label={label}
          isRequired
          autoComplete='off'
          {...(withMask && { as: MaskedInput, mask: 'date' })}
        />
      }
    />
  );
};

export default DatePickerField;
