import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { CreateEvent } from '~/domain/usecases/event/remote/CreateEvent';
import { makeRemoteCreateEvent } from '~/main/factories/usecases/event/CreateEventFactory';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import { Button } from '~/presentation/components/UI/Button';
import Form from '~/presentation/components/UI/FormFormik';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import getTypeEvent from '~/utils/getTypeEvent';
import {
  mainSchema,
  schemaRegisterEvent,
} from '~/validators/RegisterEvent/RegisterEventValidator';
import FormRegisterEvent from '../../../Form/RegisterEvent/FormRegisterEvent';
import TypeEvent from './TypeEvent';

interface RegisterEventProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterEvent = ({
  isOpen,
  onClose,
}: RegisterEventProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const { user } = useSelector((store: iStore) => store.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user?.role_.name === 'MENTOR') {
      setSelectedTab(1);
    }
  }, [user]);

  const handleClose = () => {
    formik.resetForm({ values: mainSchema.initial });
    setSelectedTab(0);
    setSelectedOptions([]);
    onClose();
  };

  const setSchema = useCallback(
    (tab: number) => {
      return user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER'
        ? mainSchema.validators
        : schemaRegisterEvent[tab];
    },
    [user]
  );

  const formik = useFormik({
    initialValues: mainSchema.initial,
    validationSchema: setSchema(selectedTab),
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      const date = new Date(values.date || new Date());
      let duration: number | undefined;

      if (selectedTab === 2) {
        const time = values.time ? new Date(values.time) : new Date();
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
      } else {
        const startTime = new Date(values.startTime || new Date());
        const endTime = new Date(values.endTime || new Date());
        startTime.setSeconds(0, 0);
        endTime.setSeconds(0, 0);
        duration = Math.round((endTime.getTime() - startTime.getTime()) / 1000);
        if (duration <= 0) {
          duration = 1;
        }
        date.setHours(startTime.getHours());
        date.setMinutes(startTime.getMinutes());
        date.setSeconds(0, 0);
      }

      const baseBody: any = {
        date: date.toISOString(),
        name: values.name,
        type: getTypeEvent(selectedTab),
        duration: selectedTab !== 2 ? duration : undefined,
        link: values.link || undefined,
        description: values.description || undefined,
      };

      if (selectedTab === 0) {
        baseBody.users = values.guests?.map(Number) || [];
        baseBody.responsable = user?.id;
      } else if (selectedTab === 1) {
        baseBody.startups = values.guests?.map(Number) || [];
        baseBody.responsable = Number(values.responsable?.value);
      } else if (selectedTab === 2) {
        baseBody.startups = values.guests?.map(Number) || [];
        baseBody.notice = values.notice
          ? Number(values.notice.value)
          : undefined;
        baseBody.noticeStep = values.noticeStep
          ? Number(values.noticeStep.value)
          : undefined;
        baseBody.responsable = user?.id;
      }

      const requestBody = { body: baseBody };

      setIsLoading(true);
      makeRemoteCreateEvent()
        .create({ body: requestBody.body })
        .then(() => {
          makeReduxListEvent().list({});
          showConfirmModal(
            selectedTab === 0
              ? 'SUBMMIT_MEETING_EVENT'
              : selectedTab === 1
              ? 'SUBMMIT_MENTORING_EVENT'
              : 'SUBMMIT_DELIVERY_EVENT'
          );
        })
        .catch(() => showConfirmModal('APPLICATION_ERROR'))
        .finally(() => {
          resetForm({ values: mainSchema.initial });
          setSelectedOptions([]);
          setIsLoading(false);
          onClose();
        });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW='800px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Novo Evento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form value={formik}>
            <Flex justify='space-between' gap='32px'>
              <FormRegisterEvent selectedTab={selectedTab} />
              <TypeEvent
                formik={formik}
                setSelectedTab={setSelectedTab}
                setSelectedOptions={setSelectedOptions}
                selectedTab={selectedTab}
                selectedOptions={selectedOptions}
                actions={['ADMIN', 'SUPERVISOR', 'MENTOR', 'MEMBER', 'LEADER']}
                resourcers={[
                  'ADMIN',
                  'SUPERVISOR',
                  'MENTOR',
                  'MEMBER',
                  'LEADER',
                ]} disableTabs={false}              />
            </Flex>
          </Form>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button variant='secondary' onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              id='submit'
              w={{ base: '100%', md: 'auto' }}
              isLoading={isLoading}
              loadingText='Concluindo cadastro'
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Agendar evento
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterEvent;
