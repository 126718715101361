import { Avatar, Flex, Grid, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { iUser } from '~/domain/interfaces/models/User';
import IconDot from '~/presentation/base/icons/iconDot';
import GenericModalLayout from '~/presentation/components/Modals/GenericModalLayout';
import getDate from '~/utils/getDate';
import { getRoleByName } from '~/utils/getRoleByName';
import StringMask from '~/utils/getStringMask';

interface DetailsUserProps {
  isOpen: boolean;
  onClose: () => void;
  user?: iUser['records'][0];
}

const roleIcon = (role: string): JSX.Element => {
  switch (role) {
    case 'ADMIN':
      return <IconDot fillColor='#E74D39' />;
    case 'SUPERVISOR':
      return <IconDot fillColor='#8BC314' />;
    case 'MENTOR':
      return <IconDot fillColor='#AC38A8' />;
    case 'LEADER':
      return <IconDot fillColor='#1997F3' />;
    case 'MEMBER':
      return <IconDot fillColor='#EF8407' />;
    default:
      return <IconDot fillColor='#CECECE' />;
  }
};

const userGender: { [key: string]: string } = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  OTHER: 'Outro',
};

const DetailsUser: React.FC<DetailsUserProps> = ({ isOpen, onClose, user }) => {
  if (!isOpen || !user) return null;

  return (
    <GenericModalLayout
      isOpen={isOpen}
      onClose={onClose}
      headerTitle='Detalhes do Usuário'
      modalSize='large'
      isPartitioned={true}
      isVerticalLine={true}
      centerContent={
        <Flex flexDirection='column' gap='10px'>
          <Flex flexDirection='row' gap='32px'>
            <Flex flexDirection='column' gap='20px'>
              <Flex flexDirection='column' gap='10px'>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Nome Completo
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {user?.fullname}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Papel Associado
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                      }}
                    >
                      {roleIcon(user?.role?.name)}
                      {getRoleByName(user?.role?.name)}
                    </span>
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Data de nascimento
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {getDate(user?.birthdate)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection='column' gap='20px'>
              <Flex flexDirection='column' gap='10px' alignItems={'center'}>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Gênero
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {userGender[user?.gender]}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    E-mail
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {user?.email}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    CPF
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {new StringMask('000.000.000-00').apply(user?.cpf)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection='column' gap='20px'>
              <Flex flexDirection='column' gap='10px'>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Status
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                      }}
                    >
                      {user?.situation === 'ACTIVE' ? (
                        <IconDot fillColor='#8BC314' />
                      ) : (
                        <IconDot fillColor='#CECECE' />
                      )}
                      {user?.situation === 'ACTIVE' ? 'Ativo' : 'Pendente'}
                    </span>
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    Telefone
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {new StringMask('(00)00000-0000').apply(user?.phone)}
                  </Text>
                </Flex>
                <Flex flexDirection='column' gap='5px'>
                  <Text fontWeight='700' fontSize='sm' color='#303950'>
                    RG
                  </Text>
                  <Text fontSize='sm' color='#303950'>
                    {user?.rg}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Grid templateColumns='1fr 1fr' gap='10px'>
            <Flex flexDirection='column' gap='5px'>
              <Text fontWeight='700' fontSize='sm' color='#303950'>
                Cargo
              </Text>
              <Text fontSize='sm' color='#303950'>
                {user?.members[0]?.job ?? 'Não informado!'}
              </Text>
            </Flex>
            <Flex flexDirection='column' gap='5px'>
              <Text fontWeight='700' fontSize='sm' color='#303950'>
                Dedicação
              </Text>
              <Text fontSize='sm' color='#303950'>
                {user?.members[0]?.workload ?? 'Não informado!'}
              </Text>
            </Flex>
            <Flex flexDirection='column' gap='5px'>
              <Text fontWeight='700' fontSize='sm' color='#303950'>
                Endereço
              </Text>
              <Text fontSize='sm' color='#303950'>
                {user?.address.street}
              </Text>
            </Flex>
            <Flex flexDirection='column' gap='5px'>
              <Text fontWeight='700' fontSize='sm' color='#303950'>
                CEP
              </Text>
              <Text fontSize='sm' color='#303950'>
                {user?.address.zipcode}
              </Text>
            </Flex>
            <Spacer />
          </Grid>
        </Flex>
      }
      startupImg={
        <Avatar size='2xl' name={user?.fullname} src={user?.avatar} />
      }
    />
  );
};

export default DetailsUser;
