import {
  AccountabilityTypes,
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  iActionSetMaxMinValues,
} from '~/domain/interfaces/redux/accountability/list';
import { ListAccountability } from '~/domain/usecases/accountability/redux/ListAccountability';

export const listRequest = (
  payload: ListAccountability.Params,
): iActionList => ({
  type: AccountabilityTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListAccountability.Model,
): iActionListSuccess => ({
  type: AccountabilityTypes.LIST_SUCCESS,
  payload: params,
});

export const setMaxMinValues = (): iActionSetMaxMinValues => ({
  type: AccountabilityTypes.SET_MAX_MIN_VALUES,
});

export const listFailed = (): iActionListFailed => ({
  type: AccountabilityTypes.LIST_FAILED,
});
