import { Box, Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import { makeReduxLoginAuth } from '~/main/factories/usecases/auth/LoginAuthFactory';
import { makeRemoteGetUserConfirmation } from '~/main/factories/usecases/user/GetUserConfirmationFactory';
import {
  IconFacebook,
  IconInstagram,
  IconLogo,
  IconLogoGovPBWhite,
  IconTwitter,
  IconYoutube,
} from '~/presentation/base/icons';
import ForgotPassword from '~/presentation/components/ForgotPassword';
import CompleteUserInfoLinkError from '~/presentation/components/Modals/Confirmation/CompleteUserInfoLinkError';
import CompleteUserInfoResendEmail from '~/presentation/components/Modals/Confirmation/CompleteUserInfoResendEmail';
import SignUpUserConfirmationSuccess from '~/presentation/components/Modals/Confirmation/SignUpUserConfirmationSuccess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import SignUpFinalization from '~/presentation/components/Modals/user/SignUpFinalization';
import Input from '~/presentation/components/UI/Input';
import Form from '~/presentation/components/UI/form';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { schemaLogin } from '~/validators/Login/LoginValidator';
import back from '../../../presentation/base/images/back.png';
import { SetPassword } from '../../components/Modals/user/SetPassword';

interface ownProps {
  token?: string;
}

const Login: React.FC<ownProps> = ({ token }): JSX.Element => {
  const { initial, validators } = schemaLogin;
  const { user, error } = useSelector((store: iStore) => store.auth);
  const [open, setOpen] = React.useState('');
  const [userID, setUserID] = React.useState(0);
  const [confirmUserStep, setConfirmUserStep] = React.useState<number>(0);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isValidToken, setIsValidToken] = React.useState(false);
  const [finalPassword, setFinalPassword] = React.useState('');

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,

    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      makeReduxLoginAuth().login({
        body: {
          email: values.login,
          password: values.password,
        },
      });
      // resetForm({ values: initial });
    },
  });

  const handleValidateToken = useCallback(
    async (token: string) => {
      try {
        const response = await makeRemoteGetUserConfirmation().get({ token });
        if (response.active === false) {
          setIsValidToken(true);
          return true;
        } else {
          showConfirmModal('COMPLETE_USER_INFO_LINK_ERROR');
          return false;
        }
      } catch {
        setIsValidToken(false);
        showConfirmModal('COMPLETE_USER_INFO_LINK_ERROR');
        return false;
      }
    },
    [setIsValidToken]
  );

  useEffect(() => {
    const validateToken = async () => {
      try {
        if (token) {
          const decodedUser: any = jwtDecode(token);
          setUserID(decodedUser?.id);
          setIsValidToken(await handleValidateToken(token));
        }
      } catch {
        setIsValidToken(false);
      }
    };
    validateToken();
    // eslint-disable-next-line no-console
    console.log('isValid', isValidToken);
  }, [token]);

  return (
    <Flex w='100%' h='100%'>
      <Flex
        w={{ base: '100%', lg: '450px' }}
        justify={{ base: 'center', lg: 'space-between' }}
      >
        <Flex justify='space-between' direction='column'>
          <Flex pl={'48px'} pt={'30px'}>
            <IconLogo />
          </Flex>
          <Form onSubmit={() => formik.handleSubmit()}>
            <Stack px='12' w='100%' spacing='28' py='8'>
              <Box w='100%'>
                <Text fontSize='2xl' fontWeight='bold' mb='1'>
                  Login
                </Text>
                <Text mb='8' fontSize='xs'>
                  Faça login para acessar o sistema da incubadora do Parque
                  Tecnológico Horizontes de Inovação
                </Text>
                <Stack spacing='4'>
                  <Input
                    id='login'
                    name='login'
                    label='Nome de usuário ou E-mail'
                    formik={formik}
                  />
                  <Input
                    id='password'
                    name='password'
                    label='Senha'
                    type='password'
                    formik={formik}
                  />
                </Stack>
                <Text fontSize='xs' textAlign='end' mt='2'>
                  <ForgotPassword />
                </Text>
                {error && (
                  <Text
                    fontSize='md'
                    color='red.500'
                    mt='3'
                    mb='-2'
                    textAlign='center'
                  >
                    E-mail ou senha inválidos
                  </Text>
                )}
                <Button
                  mt='5'
                  w='100%'
                  bg='#303950'
                  type='submit'
                  color='#F9F8FA'
                  _hover={{ background: '#4B587A' }}
                  _active={{ background: '#2D3548' }}
                >
                  Entrar
                </Button>
              </Box>
            </Stack>
          </Form>
          <Box py='8' px='12' borderTop='1px solid #CFCFCF'>
            <Text fontSize='small' textAlign='center'>
              © Parque Tecnológico Horizontes de Inovação, 2022 Governo do
              Estado da Paraíba
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flex='1'
        align='center'
        justify='space-between'
        direction='column'
        backgroundImage={back}
        backgroundRepeat='no-repeat'
        backgroundSize='100% 100%'
        color='#F9F8FA'
        fontSize='4xl'
        fontWeight='bold'
        lineHeight='130%'
        position='relative'
        display={{ base: 'none', lg: 'flex' }}
      >
        <Box mt={'30px'}>
          {' '}
          <IconLogoGovPBWhite></IconLogoGovPBWhite>
        </Box>
        <Box textAlign='center'>
          <Text fontSize='xl' fontWeight='semibold'>
            BEM-VINDO AO
          </Text>
          <Text>Parque Tecnológico</Text>
          <Text>Horizontes de Inovação</Text>
        </Box>
        <Box
          position='relative'
          bottom='0'
          fontSize='sm'
          fontWeight='semibold'
          mb='8'
          textAlign='center'
        >
          <Text>Siga nossas redes sociais</Text>
          <HStack spacing='15'>
            <IconFacebook />
            <IconTwitter />
            <IconInstagram />
            <IconYoutube />
          </HStack>
        </Box>
      </Flex>
      {token && isValidToken && (
        <>
          <SetPassword
            token={token || ''}
            isOpen={confirmUserStep === 0}
            onClose={() => setOpen('')}
            setConfirmUserStep={setConfirmUserStep}
            setFinalPassword={setFinalPassword}
          />
          <SignUpFinalization
            isOpen={confirmUserStep === 1}
            onClose={() => setOpen('')}
            setConfirmUserStep={setConfirmUserStep}
            token={token || ''}
            finalPassword={finalPassword}
          />
        </>
      )}
      <SignUpUserConfirmationSuccess />
      <CompleteUserInfoLinkError token={token ?? ''} userId={userID} />
      <CompleteUserInfoResendEmail />
      <SubmitionApplicationError />
    </Flex>
  );
};

export default Login;
