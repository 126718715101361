import { useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import {
  makeReduxListNotice,
  makeRemoteListNotice,
} from '~/main/factories/usecases/notice/ListNoticeFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { FilterContextProps, FilterProviderProps } from './types';

export type FilterNoticesFieldsType = {
  initialValue: number;
  finalValue: number;
  initialVacancies: number;
  finalVacancies: number;
  vacancies: number[];
  financial: number[];
};

export type FilterNoticesFields = Partial<FilterNoticesFieldsType>;

type FilterNoticesContextProps = FilterContextProps<FilterNoticesFields>;

const FilterNoticesContext = createContext<
  FilterNoticesContextProps | undefined
>(undefined);

const initialValues: FilterNoticesFields = {
  initialValue: undefined,
  finalValue: undefined,
  financial: undefined,
  initialVacancies: undefined,
  finalVacancies: undefined,
  vacancies: undefined,
};

export const FilterNoticesProvider: React.FC<FilterProviderProps> = ({
  children,
}) => {
  const [filter, setFilter] = useState<FilterNoticesFields | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { metadata } = useSelector((store: iStore) => store.startup);

  const [numberOfResults, setNumberOfResults] = useState<number | null>(
    () => metadata?.total ?? null
  );

  const methods = useFormik<FilterNoticesFields>({
    initialValues: filter ?? initialValues,
    onSubmit: (values) => handleFilterNotices(values),
    validateOnChange: true,
  });

  const transformNullValues = useCallback(
    (
      values: FilterNoticesFields
    ): Record<
      Exclude<keyof FilterNoticesFields, 'financial' | 'vacancies'>,
      number | undefined
    > => {
      return {
        initialValue:
          values.financial &&
          values.financial?.[0] !== null &&
          values.financial?.[0] > 0
            ? values.financial?.[0]
            : undefined,
        finalValue:
          values.financial &&
          values.financial?.[1] !== null &&
          values.financial?.[1] > 0
            ? values.financial?.[1]
            : undefined,
        initialVacancies:
          values.vacancies &&
          values.vacancies?.[0] !== null &&
          values.vacancies?.[0] > 0
            ? values.vacancies?.[0]
            : undefined,
        finalVacancies:
          values.vacancies &&
          values.vacancies?.[1] !== null &&
          values.vacancies?.[1] > 0
            ? values.vacancies?.[1]
            : undefined,
      };
    },
    []
  );

  const handleFilterNotices = useCallback((values: FilterNoticesFields) => {
    const listStartup = makeReduxListNotice();

    const { initialValue, finalValue, finalVacancies, initialVacancies } =
      transformNullValues(values);

    listStartup.list({
      query: {
        initialValue,
        finalValue,
        initialVacancies,
        finalVacancies,
      },
    });
    setFilter(() => values);
    onClose();
  }, []);

  const hasFilterActive = useMemo((): boolean => {
    return filter !== null;
  }, [filter]);

  const handleOnClose = useCallback(() => {
    onClose();
    methods.resetForm({
      values: filter !== null ? filter : initialValues,
    });
  }, [filter]);

  const handlePartialFilterOnBlur = useCallback(
    async (values: FilterNoticesFields) => {
      try {
        const listStartup = makeRemoteListNotice();

        const { initialValue, finalValue, initialVacancies, finalVacancies } =
          transformNullValues(values);

        const response = await listStartup.list({
          query: {
            initialValue,
            finalValue,
            initialVacancies,
            finalVacancies,
          },
        });

        setNumberOfResults(() => response.records.length);
      } catch (error) {
        setNumberOfResults(null);
      }
    },
    []
  );

  const handleRestoreDefault = useCallback(() => {
    const listStartup = makeReduxListStartup();
    listStartup.list({});

    methods.resetForm({
      values: initialValues,
    });

    setNumberOfResults(() => metadata?.total ?? null);
    setFilter(null);
    onClose();
  }, []);

  const value: FilterNoticesContextProps = {
    filter,
    methods,
    numberOfResults,
    handleFilter: handleFilterNotices,
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    isOpen,
    onOpen,
    onClose: handleOnClose,
  };

  return (
    <FilterNoticesContext.Provider value={value}>
      {children}
    </FilterNoticesContext.Provider>
  );
};

export const useFilterNotices = (): FilterNoticesContextProps => {
  const context = useContext(FilterNoticesContext);
  if (!context) {
    throw new Error(
      'useFilterNotices must be used within a FilterNoticesProvider'
    );
  }
  return context;
};
