import {
  Flex,
  Stack,
  FormControl,
  FormLabel,
  Box,
  Text,
} from '@chakra-ui/react';
import { Input } from '../../UI/NewInput';
import TextArea from '../../UI/textArea';
import DateInput from '../../UI/DateInput';
import FormItem from '../../UI/FormFormik/formItem';
import { ErrorMessage, Field } from 'formik';
import DatePickerField from '../../UI/NewDateInput';
import { Typography } from '../../UI/Typography';

interface ownProps {
  selectedTab: number;
}

const FormRegisterEvent = ({ selectedTab }: ownProps): JSX.Element => {
  return (
    <Stack spacing='4'>
      <FormItem name='name'>
        <Field
          as={Input}
          id='name'
          name='name'
          label='Título do evento'
          isRequired
        />
      </FormItem>

      <Flex
        flexDir='row'
        justifyContent='space-between'
        alignItems='flex-start'
        gap='30px'
      >
        <Flex flex='1' flexDirection='column'>
          <FormItem name='date'>
            <DatePickerField
              id='date'
              label={selectedTab === 2 ? 'Data limite' : 'Data do evento'}
              minDate={new Date()}
              placeholderText='__/__/____'
              dateFormat={'dd/MM/yyyy'}
            />
          </FormItem>
        </Flex>

        <Flex flex='1' flexDirection='column'>
          {selectedTab === 2 ? (
            <Flex flexDir='column' flex='50%' w='200px'>
              <FormItem name='time'>
                <DatePickerField
                  id='time'
                  label='Horário limite'
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  timeFormat='HH:mm'
                  dateFormat='HH:mm'
                  withMask={false}
                  placeholderText='00:00'
                />
              </FormItem>
            </Flex>
          ) : (
            <div>
              <Flex align='flex-end' gap='4'>
                <Box w='80px'>
                  <FormItem name='startTime' showError={false}>
                    <DatePickerField
                      id='startTime'
                      label='Horário'
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption='Time'
                      timeFormat='HH:mm'
                      dateFormat='HH:mm'
                      withMask={false}
                      placeholderText='00:00'
                    />
                  </FormItem>
                </Box>

                <Flex mb='5px'>
                  <Typography variant='b2_Inter_Regular_14px'>às</Typography>
                </Flex>

                <Box w='80px'>
                  <FormItem name='endTime' showError={false}>
                    <DatePickerField
                      id='endTime'
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption='Time'
                      timeFormat='HH:mm'
                      dateFormat='HH:mm'
                      withMask={false}
                      placeholderText='00:00'
                    />
                  </FormItem>
                </Box>
              </Flex>
              <ErrorMessage name='timeRange'>
                {(msg) => (msg ? <Text textAlign='left'>{msg}</Text> : null)}
              </ErrorMessage>
            </div>
          )}
        </Flex>
      </Flex>

      <FormItem name='link'>
        <Field
          as={Input}
          id='link'
          name='link'
          label='Link associado'
          placeholder='https://'
          isOptional
        />
      </FormItem>

      <FormItem name='description'>
        <Input
          as={TextArea}
          id='description'
          name='description'
          label='Descrição'
          isOptional
        />
      </FormItem>
    </Stack>
  );
};

export default FormRegisterEvent;
