import { useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { StartupSituations } from '~/domain/usecases/startup/remote';
import {
  makeReduxListStartup,
  makeRemoteListStartup,
} from '~/main/factories/usecases/startup/ListStartupFactory';
import { FilterContextProps, FilterProviderProps } from './types';

export type FilterStartupsFields = {
  notice?: number;
  activity?: number;
  situation?: Array<StartupSituations>;
};

type FilterStartupsContextProps = FilterContextProps<FilterStartupsFields>;

const FilterStartupsContext = createContext<
  FilterStartupsContextProps | undefined
>(undefined);

const initialValues: FilterStartupsFields = {
  notice: undefined,
  activity: undefined,
  situation: ['CANCELED', 'PENDING', 'REGULAR', 'IRREGULAR', 'SUSPENDED'],
};

export const FilterStartupsProvider: React.FC<FilterProviderProps> = ({
  children,
}) => {
  const [filter, setFilter] = useState<FilterStartupsFields | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { metadata } = useSelector((store: iStore) => store.startup);

  const [numberOfResults, setNumberOfResults] = useState<number | null>(
    () => metadata?.total ?? null
  );

  const methods = useFormik<FilterStartupsFields>({
    initialValues: filter ?? initialValues,
    onSubmit: (values) => handleFilterStartups(values),
    validateOnChange: true,
  });

  const handleFilterStartups = useCallback((values: FilterStartupsFields) => {
    const listStartup = makeReduxListStartup();
    listStartup.list({
      body: {
        ...values,
      },
    });
    setFilter(() => values);
    onClose();
  }, []);

  const hasFilterActive = useMemo((): boolean => {
    return filter !== null;
  }, [filter]);

  const handleOnClose = useCallback(() => {
    onClose();
    methods.resetForm({
      values: filter !== null ? filter : initialValues,
    });
  }, [filter]);

  const handlePartialFilterOnBlur = useCallback(
    async (values: FilterStartupsFields) => {
      try {
        const listStartup = makeRemoteListStartup();

        const notice =
          values.notice !== null && values.notice && values.notice > 0
            ? values.notice
            : undefined;
        const activity =
          values.activity !== null && values.activity && values.activity > 0
            ? values.activity
            : undefined;
        const situation = values.situation ?? undefined;

        const response = await listStartup.list({
          body: {
            notice,
            activity,
            situation,
          },
        });

        setNumberOfResults(() => response.metadata.total);
      } catch (error) {
        setNumberOfResults(null);
      }
    },
    []
  );

  const handleRestoreDefault = useCallback(() => {
    const listStartup = makeReduxListStartup();
    listStartup.list({});

    methods.resetForm({
      values: initialValues,
    });

    setNumberOfResults(() => metadata?.total ?? null);
    setFilter(null);
    onClose();
  }, []);

  const value: FilterStartupsContextProps = {
    filter,
    methods,
    numberOfResults,
    handleFilter: handleFilterStartups,
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    isOpen,
    onOpen,
    onClose: handleOnClose,
  };

  return (
    <FilterStartupsContext.Provider value={value}>
      {children}
    </FilterStartupsContext.Provider>
  );
};

export const useFilterStartups = (): FilterStartupsContextProps => {
  const context = useContext(FilterStartupsContext);
  if (!context) {
    throw new Error(
      'useFilterStartups must be used within a FilterStartupsProvider'
    );
  }
  return context;
};
