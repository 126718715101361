/* eslint-disable no-console */
import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { StartupSituations } from '~/domain/usecases/startup/remote';
import ButtonFilter from '~/presentation/components/ButtonFilter';
import { Typography } from '~/presentation/components/UI/Typography';
import { useFilterStartups } from '~/presentation/context/filters/startups';

interface ButtonFilterProps {
  showDownloadReportButton?: boolean;
  setFilter?: (value: string[]) => void;
}

const FilterStartups = ({
  showDownloadReportButton,
  setFilter,
}: ButtonFilterProps): JSX.Element => {
  const {
    handlePartialFilterOnBlur,
    handleRestoreDefault,
    hasFilterActive,
    methods,
    numberOfResults,
    isOpen,
    onOpen,
    onClose,
  } = useFilterStartups();

  const { records } = useSelector((store: iStore) => store.notice);
  const { records: activity } = useSelector((store: iStore) => store.activity);

  const situations: { value: StartupSituations; label: string }[] = [
    {
      value: 'PENDING',
      label: 'Pendente',
    },
    {
      value: 'REGULAR',
      label: 'Regular',
    },
    {
      value: 'IRREGULAR',
      label: 'Irregular',
    },
    {
      value: 'SUSPENDED',
      label: 'Suspensa',
    },
    {
      value: 'CANCELED',
      label: 'Cancelada',
    },
  ];

  return (
    <>
      <ButtonFilter
        filterActive={hasFilterActive}
        action={onOpen}
        showDownloadReportButton={showDownloadReportButton}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={methods.handleSubmit}>
          <ModalContent
            maxW={{ base: '100%', breakpoint1024: '408px' }}
            minH={{ base: '574px', breakpoint1024: '522px' }}
            margin={{ base: '0px', breakpoint1024: 'auto' }}
            borderRadius={{
              base: '10px 10px 0px 0px',
              breakpoint1024: '10px 10px 10px 10px',
            }}
            containerProps={{
              flexDirection: 'column',
              justifyContent: 'end',
            }}
          >
            <ModalHeader fontFamily='Inter' fontSize='18px' fontWeight='700'>
              Filtrar consulta
            </ModalHeader>
            <ModalCloseButton
              css={{
                ':active, :focus': {
                  boxShadow: '0px 0px 15px 0px #1997F380',
                  borderWidth: '2px',
                  borderColor: '#1997F3',
                },
              }}
            />
            <ModalBody fontFamily='Inter' fontWeight='600' fontSize='14px'>
              <Text mb='11px'>Edital associado</Text>

              <Select
                fontSize='14px'
                {...methods.getFieldProps('notice')}
                onChange={(e) => {
                  methods.setFieldValue('notice', Number(e.target.value));
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    notice:
                      e.target.value !== undefined
                        ? Number(e.target.value)
                        : undefined,
                  });
                }}
                css={{
                  ':active, :focus': {
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
              >
                <option
                  value={undefined}
                  style={{
                    color: '#222222',
                  }}
                >
                  Todos
                </option>
                {records.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      style={{
                        color: '#222222',
                      }}
                    >
                      {item.code}
                    </option>
                  );
                })}
              </Select>

              <Text mt='20px' mb='11px'>
                Segmento
              </Text>

              <Select
                placeholder='Todos'
                defaultValue={methods.values.activity}
                fontSize='14px'
                {...methods.getFieldProps('activity')}
                value={methods.values.activity}
                onChange={(e) => {
                  methods.setFieldValue('activity', Number(e.target.value));
                  handlePartialFilterOnBlur({
                    ...methods.values,
                    activity: Number(e.target.value),
                  });
                }}
              >
                {activity.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      style={{
                        color: '#222222',
                      }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Select>

              <Text mt='20px' mb='12px'>
                Situação
              </Text>

              <Box
                fontWeight='400'
                color='#484848'
                w={'100%'}
                display={'flex'}
                gap={1}
                flexDir={'row'}
                flexWrap={'wrap'}
              >
                {situations.map((situation, index) => {
                  const situationIsAlreadyChecked =
                    methods.values.situation?.includes(situation.value);

                  return (
                    <HStack
                      key={index}
                      spacing={2.5}
                      px={4}
                      py={2.5}
                      borderWidth={1}
                      borderStyle={'solid'}
                      borderColor={
                        situationIsAlreadyChecked ? 'blue.2' : 'white.4'
                      }
                      textColor={situationIsAlreadyChecked ? 'blue.2' : 'black'}
                      backgroundColor={
                        situationIsAlreadyChecked ? 'blue.6' : 'white'
                      }
                      borderRadius={5}
                      cursor={'pointer'}
                      _hover={{
                        backgroundColor: situationIsAlreadyChecked
                          ? 'blue.5'
                          : 'white.6',
                      }}
                      w={'max'}
                      {...methods.getFieldProps('situation')}
                      onClick={() => {
                        if (situationIsAlreadyChecked) {
                          methods.setFieldValue(
                            'situation',
                            methods.values.situation?.filter(
                              (item) => item !== situation.value
                            )
                          );
                        } else {
                          methods.setFieldValue('situation', [
                            ...(methods.values.situation || []),
                            situation.value,
                          ]);
                        }

                        const situations = methods.values.situation ?? [];

                        handlePartialFilterOnBlur({
                          ...methods.values,
                          situation: [...situations, situation.value],
                        });
                      }}
                    >
                      {situationIsAlreadyChecked && <CheckIcon />}
                      <Typography variant='h7_Inter_Medium_14px'>
                        {situation.label}
                      </Typography>
                    </HStack>
                  );
                })}
              </Box>
            </ModalBody>
            <Divider borderColor='#CECECE' />
            <ModalFooter
              gap='12px'
              justifyContent='space-between'
              flexDirection={{
                base: 'column-reverse',
                breakpoint1024: 'row',
              }}
            >
              <Button
                maxW='172px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='outline'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                borderColor='#9C9C9C'
                borderRadius='5px'
                color='#484848'
                px='24px'
                py='11.5px'
                _hover={{ bg: '#EDEDED' }}
                css={{
                  ':active, :focus': {
                    background: '#FDFDFD',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
                onClick={() => handleRestoreDefault()}
              >
                Restaurar padrões
              </Button>
              <Button
                maxW='137px'
                minW={{ base: '100%', breakpoint1024: '0px' }}
                variant='solid'
                fontFamily='Inter'
                fontSize='14px'
                fontWeight='500'
                color='#FDFDFD'
                bg='#222222'
                px='24px'
                py='11.5px'
                type='submit'
                disabled={numberOfResults === 0}
                borderRadius='5px'
                _hover={{ bg: '#484848' }}
                css={{
                  ':active, :focus': {
                    background: '#222222',
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  },
                }}
              >
                Aplicar filtros {`(${numberOfResults ?? 0})`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default FilterStartups;
